import React from "react";
import "../../resultanalysis.css";
import { TableHead, TableRow, TableCell, Typography } from "@mui/material";
import { overAllPercentageHeader } from "../../../../../../../Utils/globalUtils";

// ==================================|| OVERALL PERCENTAGE TABLE HEADER ||===================================== //

export const OverallPercentageTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        {overAllPercentageHeader?.map((text, index) => (
          <TableCell className="overall-percentage-table-cell" key={index}>
            <Typography className="overall-percentage-header-text">
              {text}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
