import React from "react";
import {
  Alert,
  Stack,
  AlertTitle,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import "../Timetable.css";

// ==============================|| WARNINGS ||============================== //

const RoomAnalysisWarnings = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const menu = useSelector((state) => state.menu);
  const warningsAnalysisList = menu.warningsAnalysisList;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {warningsAnalysisList?.length > 0 && (
        <Grid sx={{ mt: 6 }}>
          <div>
            <Alert severity="warning" className="import-warning">
              <Stack direction="row">
                <AlertTitle className="import-warning__alert-title">
                  <b>Warnings : {warningsAnalysisList?.length}</b>
                </AlertTitle>
                {warningsAnalysisList?.length !== 0 && (
                  <Stack direction="row" className="view_icon_failed">
                    <IconButton
                      className="alert-icon-button-border"
                      onClick={() => {
                        handleOpen();
                      }}
                    >
                      <VisibilityIcon className="visibilityIcon-color" />
                      <Typography className="view_title">View</Typography>
                    </IconButton>
                  </Stack>
                )}
              </Stack>
            </Alert>
          </div>
          <div>
            <Dialog
              fullScreen={fullScreen}
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              PaperProps={{
                sx: {
                  maxWidth: "38rem",
                  width: "100%",
                  height: "auto",
                  minHeight: "auto",
                },
              }}
              className="modal-overlay-background"
            >
              <Stack direction="row">
                <DialogTitle id="responsive-dialog-title">
                  <b>{"Warnings List"}</b>
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: "#64748B",
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Divider />
              <DialogContent>
                <DialogContentText className="errorDialog-style">
                  {warningsAnalysisList?.map((val, i) => (
                    <p>{` ${i + 1} . ${val}`}</p>
                  ))}
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </div>
        </Grid>
      )}
    </>
  );
};

export default RoomAnalysisWarnings;
