import { uiTexts } from "../../../../../../Utils/uiTexts";
import "jspdf-autotable";
import CampusPlannerLogo from "../../../../../../Assets/Images/CampusPlannerLogo.png";
import VVVLogo from "../../../../../../Assets/Images/vvvclogo.png";
import {
  getUniqueCount,
  roundedPercentage,
  sortDataByLocaleCompare,
} from "../../../../../../Utils/commonMethods";

// =================================== || STAFF PREFERENCE MATCH REPORT || ======================================= //

export const staffPreferenceMatchReport = (doc, staffPreferenceMatchData) => {
  const {
    labelText: {
      sNoText,
      departmentText,
      staffText,
      subjectText,
      preferredDayText,
      isPreferenceMatchingText,
      staffPreferencesMatchedText,
      staffPreferencesNotMatchedText,
      overAllMatchPercentageText,
      departmentNameText,
      staffNameText,
    },
    symbolText: { colonText },
    pdfReportText: { vvvCollegeText, staffPreferenceMatchReportText },
    buttonText: { yesText, noText },
  } = uiTexts;

  const {
    staffPreferenceMatched,
    staffPreferenceNotMatched,
    overAllPercentage,
  } = staffPreferenceMatchData || {};

  const staffPreferenceMatchDTOList =
    staffPreferenceMatchData?.staffPreferenceMatchDTOList || [];

  const uniqueDepartmentCount = getUniqueCount(
    staffPreferenceMatchDTOList,
    departmentNameText,
  );
  const uniqueStaffCount = getUniqueCount(
    staffPreferenceMatchDTOList,
    staffNameText,
  );

  const sortedStaffPreferenceMatchData = sortDataByLocaleCompare(
    staffPreferenceMatchDTOList,
    departmentNameText,
  );

  doc.addPage();
  const newStartY = 20;
  const width = doc.internal.pageSize.getWidth();

  doc.setFontSize(18);
  doc.addImage(VVVLogo, "PNG", 10, newStartY - 15, 15, 15);
  doc.addImage(CampusPlannerLogo, "JPG", 320, newStartY - 15, 25, 15);
  doc.text(vvvCollegeText, width / 2, newStartY - 5, { align: "center" });
  doc.setFontSize(13);
  doc.text(staffPreferenceMatchReportText, width / 2, newStartY + 5, {
    align: "center",
  });

  doc.setFontSize(12);
  const firstTextY = newStartY + 15;
  const secondTextY = firstTextY;
  const thirdTextY = firstTextY + 8;

  doc.text(
    staffPreferencesMatchedText + colonText + staffPreferenceMatched,
    14,
    firstTextY,
    { align: "left" },
  );
  doc.text(
    staffPreferencesNotMatchedText + colonText + staffPreferenceNotMatched,
    width - 14,
    secondTextY,
    { align: "right" },
  );
  doc.text(
    overAllMatchPercentageText +
      colonText +
      roundedPercentage(overAllPercentage),
    14,
    thirdTextY,
    { align: "left" },
  );

  const staffPreferenceMatchHeaders = [
    sNoText,
    `${departmentText} (${uniqueDepartmentCount})`,
    `${staffText} (${uniqueStaffCount})`,
    subjectText,
    preferredDayText,
    isPreferenceMatchingText,
  ];

  const staffPreferenceMatchRows = sortedStaffPreferenceMatchData?.map(
    (detail, index) => [
      index + 1,
      detail.departmentName,
      detail.staffName,
      detail.subjectName,
      detail.dayPreference?.join(", "),
      detail.matchedOrNot ? yesText : noText,
    ],
  );

  doc.autoTable({
    head: [staffPreferenceMatchHeaders],
    body: staffPreferenceMatchRows,
    startY: newStartY + 25,
    theme: "grid",
    halign: "center",
    valign: "middle",
    styles: { fontSize: 10, minCellHeight: 10 },
  });
};
