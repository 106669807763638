/* eslint-disable array-callback-return */
import React from "react";
import {
  Grid,
  Button,
  List,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  ListItem,
  Tooltip,
  ListItemText,
  Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  DeleteStaffModel,
  EditStaffModel,
} from "../../../Store/Reducers/staff_menu";
import EditStaff from "./Staff Models/EditStaff";
import DeleteStaffConfirmation from "./Staff Models/DeleteStaffConfirmation";
import "./Staff.css";

// ==============================|| LHS STAFF LIST ||============================== //

const StaffList = ({ handleStaffClick, handleRemoveStaff }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.staff_menu);
  const editStaffModel = menu.editStaffModel;
  const deleteStaffModel = menu.deleteStaffModel;
  const staffList = menu.staffList;
  const staffId = menu.staffId;
  const staffInFixedSlot = menu.staffInFixedSlot;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const departmentList = infrastructure_menu.departmentList;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  const [anchorEl, setAnchorEl] = React.useState(null);

  // Function used to show the action menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function used to close the action menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Used to sort the staff list based on the department
  const sortedStaffList = [...staffList].sort(
    (a, b) => a.departmentId - b.departmentId,
  );

  // Function used for getting Staff code
  const getStaffCode = (id) => {
    const staff = staffList.find((res) => res.id === id);

    if (staff && staffInFixedSlot.includes(id)) {
      return (
        <span>
          <Tooltip
            title="Can't Delete because it exists in Fixed Slot"
            placement="bottom-start"
          >
            <span className="dot-fixed"></span>
          </Tooltip>
          <Tooltip title={staff.staffCode}>
            <span>{staff.staffCode}</span>
          </Tooltip>
        </span>
      );
    } else {
      return (
        <Tooltip title={staff.staffCode}>
          <span>{staff.staffCode}</span>
        </Tooltip>
      );
    }
  };

  // Function used for getting Department name
  const getDepartmentName = (id) => {
    const departments = departmentList.find((res) => res.id === id);

    return (
      <Tooltip title={departments?.departmentName}>
        {departments?.departmentName.length > 25
          ? `${departments?.departmentName.substring(0, 25)}...`
          : departments?.departmentName}
      </Tooltip>
    );
  };

  const HeaderGridItem = ({ xs, text }) => (
    <Grid item xs={xs}>
      <ListItemText
        primary={text}
        sx={{ textAlign: "left", flex: "1" }}
        primaryTypographyProps={{
          sx: { fontWeight: "bold" },
        }}
      />
    </Grid>
  );

  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingBottom: "0px",
        }}
        className="staff-list-style "
      >
        <>
          <ListItem
            className="name-text-color"
            sx={{ marginLeft: "-8px !important" }}
          >
            <Grid container alignItems="center" spacing={2}>
              <HeaderGridItem xs={2.7} text="Staff Reference" />

              <HeaderGridItem xs={4.3} text="Staff Name" />

              <HeaderGridItem xs={4} text="Department" />
            </Grid>
          </ListItem>

          <Divider className="staff-list-divider" />
        </>
      </List>

      <div className="staff-list-height">
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            paddingTop: "0px",
          }}
        >
          {sortedStaffList?.map((obj, index) => (
            <React.Fragment key={obj.departmentId}>
              {index !== 0 &&
                sortedStaffList[index - 1].departmentId !==
                  obj.departmentId && (
                  <Divider sx={{ color: "grey", width: "100%" }} flexItem />
                )}

              <Button
                className={
                  staffId === obj.id
                    ? "staff-content--button--selected staff-content--button"
                    : "staff-content--button"
                }
                onClick={() => {
                  handleStaffClick(obj.id);
                }}
              >
                <ListItem
                  className="stafflist"
                  sx={{ marginLeft: "-8px !important" }}
                >
                  <Grid container alignItems="center" spacing={2}>
                    <Grid
                      item
                      xs={2.7}
                      style={{
                        color: obj.isDummy === false ? "black" : "gray",
                      }}
                    >
                      <Typography className="staff-list-text-style">
                        {getStaffCode(obj.id)}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={4.3}
                      style={{
                        color: obj.isDummy === false ? "black" : "gray",
                      }}
                    >
                      <Tooltip title={obj.staffName}>
                        <Typography className="staff-list-text-style">
                          {obj.staffName}
                        </Typography>
                      </Tooltip>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      style={{
                        color: obj.isDummy === false ? "black" : "gray",
                      }}
                    >
                      <Typography className="staff-list-text-style">
                        {obj.departmentId === null
                          ? "null"
                          : getDepartmentName(obj.departmentId)}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={1}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <IconButton
                        aria-label="more"
                        onClick={handleClick}
                        aria-haspopup="true"
                        aria-controls="long-menu"
                        className="staff-menu-icon"
                      >
                        <MoreVertIcon className="moreVertIcon-style" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </ListItem>
              </Button>
            </React.Fragment>
          ))}
        </List>
      </div>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            dispatch(EditStaffModel(true));
            handleClose();
          }}
          disabled={
            algorithmStatus !== null &&
            algorithmStatus !== "FAILED" &&
            algorithmStatus !== "UNKNOWN" &&
            algorithmStatus !== "NOT_CREATED"
          }
        >
          Edit
        </MenuItem>

        <MenuItem
          onClick={() => {
            dispatch(DeleteStaffModel(true));
            handleClose();
          }}
          disabled={
            staffInFixedSlot.includes(staffId) ||
            (algorithmStatus !== null &&
              algorithmStatus !== "FAILED" &&
              algorithmStatus !== "UNKNOWN" &&
              algorithmStatus !== "NOT_CREATED")
          }
        >
          Delete
        </MenuItem>
      </Menu>

      {editStaffModel === true && <EditStaff />}

      {deleteStaffModel === true && (
        <DeleteStaffConfirmation handleRemoveStaff={handleRemoveStaff} />
      )}
    </>
  );
};

export default StaffList;
