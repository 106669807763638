import React from "react";
import { Grid, Table, TableContainer } from "@mui/material";
import { StaffWorkLoadBalancingTableHeader } from "./StaffWorkLoadBalancingTableHeader";
import { StaffWorkLoadBalancingTableItem } from "./StaffWorkLoadBalancingTableItem";

// =======================================|| STAFF WORKLOAD BALANCING TABLE ||======================================== //

export const StaffWorkLoadBalancingTable = ({
  filteredStaffWorkLoadBalancingData,
}) => {
  return (
    <Grid item xs={12}>
      <TableContainer className="result-analysis-modal-rhs-content-scroll">
        <Table stickyHeader>
          <StaffWorkLoadBalancingTableHeader
            filteredStaffWorkLoadBalancingData={
              filteredStaffWorkLoadBalancingData
            }
          />

          <StaffWorkLoadBalancingTableItem
            filteredStaffWorkLoadBalancingData={
              filteredStaffWorkLoadBalancingData
            }
          />
        </Table>
      </TableContainer>
    </Grid>
  );
};
