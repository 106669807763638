import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

// =======================================|| ANALYSIS MODAL SEARCH TEXTFIELD ||======================================= //

export const AnalysisModalSearchTextField = ({
  searchData,
  handleSearch,
  label,
  setSearchData,
}) => {
  return (
    <TextField
      label={label}
      value={searchData}
      variant="outlined"
      onChange={(e) => handleSearch(e)}
      sx={{
        "& .MuiOutlinedInput-root": {
          height: 45,
          width: 250,
        },
        "& .MuiInputLabel-root": {
          marginTop: "-5px",
        },
      }}
      InputProps={{
        endAdornment: (
          <>
            {searchData !== "" && (
              <InputAdornment position="end">
                <ClearIcon
                  onClick={() => setSearchData("")}
                  className="show-cursor"
                />
              </InputAdornment>
            )}

            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          </>
        ),
      }}
    />
  );
};
