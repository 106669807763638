import React from "react";
import { Stack, IconButton, Typography, Divider } from "@mui/material";
import "../../../../Dashboard/ShortcutModal/shortcutmodal.css";
import CloseIcon from "@mui/icons-material/Close";
import "../DataAnalysis/dataanalysis.css";

// ====================================== ANALYSIS MODAL HEADER ===================================== //

export const AnalysisModalHeader = ({ headerText, closeClick }) => {
  return (
    <>
      <Stack
        direction="row"
        className="shortcut-modal-header-style data-analysis-modal-header-margin"
      >
        <Typography className="shortcut-modal-header">{headerText}</Typography>

        <IconButton onClick={closeClick}>
          <CloseIcon className="data-analysis-modal-close-icon" />
        </IconButton>
      </Stack>

      <Divider />
    </>
  );
};
