import React from "react";
import { Grid, Table, TableContainer } from "@mui/material";
import { SubjectContinuousHoursPreferenceMatchTableHeader } from "./SubjectContinuousHoursPreferenceMatchTableHeader";
import { SubjectContinuousHoursPreferenceMatchTableItem } from "./SubjectContinuousHoursPreferenceMatchTableItem";

// ===============================|| SUBJECT CONTINUOUS HOURS PREFERENCE MATCH TABLE ||================================= //

export const SubjectContinuousHoursPreferenceMatchTable = ({
  filteredSubjectContinuousHoursPreferenceMatchData,
}) => {
  return (
    <Grid item xs={12}>
      <TableContainer className="data-analysis-modal-rhs-content-scroll result-analysis-subject-continuous-hours-scroll">
        <Table stickyHeader>
          <SubjectContinuousHoursPreferenceMatchTableHeader
            filteredSubjectContinuousHoursPreferenceMatchData={
              filteredSubjectContinuousHoursPreferenceMatchData
            }
          />

          <SubjectContinuousHoursPreferenceMatchTableItem
            filteredSubjectContinuousHoursPreferenceMatchData={
              filteredSubjectContinuousHoursPreferenceMatchData
            }
          />
        </Table>
      </TableContainer>
    </Grid>
  );
};
