import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { homePageTableHeader } from "../../../../../Utils/globalUtils";
import "../../HomePage.css";

// ========================================|| HOME PAGE TABLE HEADER ||========================================= //

export const HomePageTableHeader = () => {
  return (
    <TableRow className="semester-table-header-sticky">
      {homePageTableHeader?.map((header, index) => (
        <TableCell key={index} className="semester-table-head-title">
          {header}
        </TableCell>
      ))}
    </TableRow>
  );
};
