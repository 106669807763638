import React, { useState, useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { getStaffWorkLoadBalancing } from "../../../../../../../Services/ResultAnalysisService";
import { StaffWorkLoadBalancingFilters } from "./StaffWorkLoadBalancingFilters";
import { StaffWorkLoadBalancingRhsContent } from "./StaffWorkLoadBalancingRhsContent";

// =======================================|| STAFF WORKLOAD BALANCING ||======================================= //

export const StaffWorkLoadBalancing = () => {
  const departmentList = useSelector(
    (state) => state.infrastructure_menu.departmentList,
  );

  const [fetchData, setFetchData] = useState(false);
  const [selectedDepartments, setSelectedDepartments] =
    useState(departmentList);
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState();
  const [searchData, setSearchData] = useState("");
  const [
    filteredStaffWorkLoadBalancingData,
    setFilteredStaffWorkLoadBalancingData,
  ] = useState();

  useEffect(() => {
    setSelectedDepartmentIds(
      selectedDepartments?.map((department) => department.id),
    );
  }, [selectedDepartments]);

  const getFilteredData = async () => {
    setFetchData(true);

    const staffWorkLoadBalancingData = {
      departmentIds: selectedDepartmentIds,
    };

    await getStaffWorkLoadBalancing(staffWorkLoadBalancingData).then(
      ({ data }) => {
        const filteredDetails = data?.staffWorkLoadBalanceDTOS?.filter(
          (staff) =>
            staff.staffName.toLowerCase().includes(searchData?.toLowerCase()),
        );

        const updatedStaffWorkLoadBalancingData = {
          ...data,
          staffWorkLoadBalanceDTOS: filteredDetails,
        };

        setFilteredStaffWorkLoadBalancingData(
          updatedStaffWorkLoadBalancingData,
        );
        setFetchData(false);
      },
    );
  };

  useEffect(() => {
    getFilteredData();
  }, [selectedDepartmentIds, searchData]);

  return (
    <Box className="data-analysis-modal-rhs-content">
      <Stack className="data-analysis-filter-part">
        <StaffWorkLoadBalancingFilters
          departments={departmentList}
          selectedDepartments={selectedDepartments}
          setSelectedDepartments={setSelectedDepartments}
          setSelectedDepartmentIds={setSelectedDepartmentIds}
          searchData={searchData}
          setSearchData={setSearchData}
          filteredStaffWorkLoadBalancingData={
            filteredStaffWorkLoadBalancingData
          }
        />

        <StaffWorkLoadBalancingRhsContent
          fetchData={fetchData}
          filteredStaffWorkLoadBalancingData={
            filteredStaffWorkLoadBalancingData
          }
        />
      </Stack>
    </Box>
  );
};
