import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoginService from "../../../Services/LoginService";
import {
  SemesterDetails,
  SelectedSemesterId,
} from "../../../Store/Reducers/login_menu";
import Loader from "../../Loader/Loader";
import { Header } from "../../Header/Header";
import MainPage from "../MainPage";
import { HomePageContent } from "./HomePageContent/HomePageContent";
import { AddSemesterModal } from "./AddSemesterModal/AddSemesterModal";
import "./HomePage.css";

// ==========================================|| HOME PAGE ||============================================== //

export default function HomePage() {
  const dispatch = useDispatch();

  const { semesterDetails, semesterLoader } = useSelector(
    (state) => state.login_menu,
  );

  //Get SemesterName to display in select field
  const getSelectedSemesterId = () => {
    return (
      semesterDetails?.find(
        (semester) => semester.id === parseInt(sessionStorage.getItem("id")),
      )?.id || 0
    );
  };

  const selectedSemesterId = getSelectedSemesterId();
  dispatch(SelectedSemesterId(selectedSemesterId));

  const [importDataFromSemesterId, setImportDataFromSemesterId] = useState(0);
  const [academicYear, setAcademicYear] = useState(0);
  const [semesterType, setSemesterType] = useState(0);
  const [addSemesterError, setAddSemesterError] = useState("");
  const [addSemesterModal, setAddSemesterModal] = useState(false);
  const [addNewClick, setAddNewClick] = useState(false);
  const [selectedSemester, setSelectedSemester] = useState();

  const handleAcademicYearChange = (event) => {
    setAddSemesterError("");
    setAddNewClick(false);
    setAcademicYear(event.target.value);
  };

  const handleSemesterTypeChange = (event) => {
    setAddSemesterError("");
    setAddNewClick(false);
    setSemesterType(event.target.value);
  };

  const handleSemesterChange = (event) => {
    setAddSemesterError("");
    setAddNewClick(false);
    setImportDataFromSemesterId(event.target.value);
  };

  const handleCloseSemesterModal = () => {
    setAddSemesterModal(false);
    setAcademicYear(0);
    setSemesterType(0);
    setAddSemesterError("");
    setImportDataFromSemesterId(0);
  };

  const handleSaveNewSemester = () => {
    const data = {
      importDataFromSemesterId: importDataFromSemesterId,
      academicYear: academicYear,
      semesterType: semesterType,
    };

    LoginService.addSemester(data)
      .then((res) => {
        dispatch(SemesterDetails([...semesterDetails, res.data]));
        handleCloseSemesterModal();
      })
      .catch((error) => {
        setAddSemesterError(error.response.data);
      });

    setAddNewClick(true);
  };

  return (
    <>
      <Header />

      <MainPage />

      {!semesterLoader ? (
        <Loader />
      ) : (
        <HomePageContent
          setAddSemesterModal={setAddSemesterModal}
          setSelectedSemester={setSelectedSemester}
        />
      )}

      {addSemesterModal && (
        <AddSemesterModal
          academicYear={academicYear}
          semesterType={semesterType}
          importDataFromSemesterId={importDataFromSemesterId}
          addSemesterModal={addSemesterModal}
          setAddSemesterModal={setAddSemesterModal}
          handleAcademicYearChange={handleAcademicYearChange}
          handleSemesterTypeChange={handleSemesterTypeChange}
          handleSaveNewSemester={handleSaveNewSemester}
          handleCloseSemesterModal={handleCloseSemesterModal}
          addSemesterError={addSemesterError}
          handleSemesterChange={handleSemesterChange}
          addNewClick={addNewClick}
        />
      )}
    </>
  );
}
