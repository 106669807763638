import React, { useState } from "react";
import "../../Timetable.css";
import useRetrieveFunctions from "../../../../../Components/CustomHooks/useRetrieveFunction";
import { uiTexts } from "../../../../../Utils/uiTexts";
import { useSelector } from "react-redux";
import {
  getOverAllPercentage,
  getStaffPreferenceMatch,
  getStaffWorkLoadBalancing,
  getSubjectBalancing,
  getSubjectContinuousHoursPreferenceMatch,
  getGroupSchedules,
} from "../../../../../Services/ResultAnalysisService";
import { AlertComponent } from "../../../../CommonComponents/AlertComponent";
import { ResultAnalysisModal } from "../ResultAnalysis/ResultAnalysisModal/ResultAnalysisModal";
import { resultAnalysisReport } from "./ResultAnalysisReport/ResultAnalysisReport";

// =====================================|| RESULT ANALYSIS ||======================================== //

export const ResultAnalysis = ({
  retrieveDepartmentList,
  retrieveClassList,
  retrieveDay,
  retrieveTime,
}) => {
  const {
    alertTitleText: { resultAnalysisText },
  } = uiTexts;

  const { retrieveOverAllPercentageData } = useRetrieveFunctions();
  const { dayList, timeList } = useSelector((state) => state.menu);

  const [openResultAnalysis, setOpenResultAnalysis] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const fetchData = async () => {
    setIsLoader(true);
    await retrieveOverAllPercentageData();
    await retrieveDepartmentList();
    await retrieveClassList();
    await retrieveDay();
    await retrieveTime();
    setIsLoader(false);
  };

  const handleOpenResultAnalysisModal = () => {
    fetchData();
    setOpenResultAnalysis(true);
  };

  const downLoadClick = () => {
    const commonStaffData = {
      departmentIds: null,
    };

    const commonSubjectData = {
      departmentIds: null,
      classIds: null,
    };

    Promise.all([
      getOverAllPercentage(),
      getStaffPreferenceMatch(commonStaffData),
      getStaffWorkLoadBalancing(commonStaffData),
      getSubjectBalancing(commonSubjectData),
      getSubjectContinuousHoursPreferenceMatch(commonSubjectData),
      getGroupSchedules(),
    ]).then(
      ([
        overAllPercentageResponse,
        staffPreferenceMatchResponse,
        staffWorkLoadBalancingResponse,
        subjectBalancingResponse,
        subjectContinuousHoursPreferenceMatchResponse,
        groupSchedulesResponse,
      ]) => {
        resultAnalysisReport(
          overAllPercentageResponse?.data,
          staffPreferenceMatchResponse?.data,
          staffWorkLoadBalancingResponse?.data,
          subjectBalancingResponse?.data,
          subjectContinuousHoursPreferenceMatchResponse?.data,
          groupSchedulesResponse?.data,
          dayList,
          timeList,
        );
      },
    );
  };

  return (
    <>
      <AlertComponent
        severity="info"
        alertCss="import-warning data-analysis-warning"
        title={resultAnalysisText}
        viewClick={handleOpenResultAnalysisModal}
        downloadClick={downLoadClick}
      />

      {openResultAnalysis && (
        <ResultAnalysisModal
          closeClick={() => setOpenResultAnalysis(false)}
          isLoader={isLoader}
        />
      )}
    </>
  );
};
