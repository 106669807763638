import React from "react";
import { Stack } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { AnalysisRhsInfo } from "../../../../Analysis/AnalysisCommonComponents/AnalysisRhsInfo";

// =======================================|| STAFF WORKLOAD RHS INFO ||======================================= //

export const StaffWorkLoadRhsInfo = ({ filteredStaffWorkLoadData }) => {
  const {
    labelText: {
      staffExceedingIdealTeachingHours,
      staffBelowIdealTeachingHours,
    },
  } = uiTexts;

  const {
    numberOfStaffExceedingIdealHours,
    numberOfStaffNotExceedingIdealHours,
  } = filteredStaffWorkLoadData || {};

  return (
    <Stack
      direction="row"
      className="data-analysis-filter-part data-analysis-info-gap"
    >
      <AnalysisRhsInfo
        label={staffExceedingIdealTeachingHours}
        value={numberOfStaffExceedingIdealHours}
      />

      <AnalysisRhsInfo
        label={staffBelowIdealTeachingHours}
        value={numberOfStaffNotExceedingIdealHours}
      />
    </Stack>
  );
};
