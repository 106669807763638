import React from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  Stack,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { GroupSchedulesAccordionContent } from "./GroupSchedulesAccordionContent";

// ====================================|| GROUP SCHEDULES ACCORDIONS ||======================================== //

export const GroupSchedulesAccordion = ({ filteredGroupSchedulesData }) => {
  const {
    toolTipText: { groupSchedulingMismatchText },
  } = uiTexts;

  const getSubjectCount = (schedule) => {
    const subjectCount =
      schedule?.matchedCourses?.courseNames?.length +
      schedule?.unMatchedCourses?.length;
    return subjectCount;
  };

  return (
    <div className="data-analysis-modal-rhs-content-scroll group-schedule-accordion-scroll">
      {filteredGroupSchedulesData?.groupScheduleResultDTOList?.map(
        (schedule) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Stack
                direction="row"
                className="group-schedules-accordion-header"
              >
                {!schedule?.sameSlotSchedulingMatchedOrNot && (
                  <Tooltip title={groupSchedulingMismatchText} placement="top">
                    <div className="unmatched-group-schedule-indicator" />
                  </Tooltip>
                )}

                <Typography className="group-schedules-accordion-header-name">
                  {schedule.groupName} ({getSubjectCount(schedule)})
                </Typography>
              </Stack>
            </AccordionSummary>

            <GroupSchedulesAccordionContent schedule={schedule} />
          </Accordion>
        ),
      )}
    </div>
  );
};
