import React, { useState, useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { getStaffWorkLoadAnalysis } from "../../../../../../../Services/DataAnalysisService";
import { StaffWorkLoadFilters } from "./StaffWorkLoadFilters";
import { StaffWorkloadRhsContent } from "./StaffWorkloadRhsContent";

// ==============================================|| STAFF WORKLOAD ||============================================ //

export const StaffWorkload = () => {
  const staffWorkLoadAnalysisData = useSelector(
    (state) => state.dataanalysis_menu.staffWorkLoadAnalysisData,
  );

  const [fetchData, setFetchData] = useState(false);
  const [selectedDepartments, setSelectedDepartments] = useState(
    staffWorkLoadAnalysisData?.departmentDetails,
  );
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState();
  const [searchData, setSearchData] = useState("");
  const [filteredStaffWorkLoadData, setFilteredStaffWorkLoadData] = useState();

  useEffect(() => {
    setSelectedDepartmentIds(
      selectedDepartments?.map((department) => department.departmentId),
    );
  }, [selectedDepartments]);

  const getFilteredData = async () => {
    setFetchData(true);

    const data = {
      departmentIds: selectedDepartmentIds,
    };

    await getStaffWorkLoadAnalysis(data).then(({ data }) => {
      const filteredDetails = data?.staffDetails?.filter(
        (staff) =>
          staff.staffName.toLowerCase().includes(searchData?.toLowerCase()) ||
          staff.staffRefName.toLowerCase().includes(searchData?.toLowerCase()),
      );

      const updatedStaffWorkLoadData = {
        ...data,
        staffDetails: filteredDetails,
      };

      setFilteredStaffWorkLoadData(updatedStaffWorkLoadData);
      setFetchData(false);
    });
  };

  useEffect(() => {
    getFilteredData();
  }, [selectedDepartmentIds, searchData]);

  return (
    <Box className="data-analysis-modal-rhs-content">
      <Stack className="data-analysis-filter-part">
        <StaffWorkLoadFilters
          departments={staffWorkLoadAnalysisData?.departmentDetails}
          selectedDepartments={selectedDepartments}
          setSelectedDepartments={setSelectedDepartments}
          setSelectedDepartmentIds={setSelectedDepartmentIds}
          searchData={searchData}
          setSearchData={setSearchData}
          filteredStaffWorkLoadData={filteredStaffWorkLoadData}
        />

        <StaffWorkloadRhsContent
          fetchData={fetchData}
          filteredStaffWorkLoadData={filteredStaffWorkLoadData}
        />
      </Stack>
    </Box>
  );
};
