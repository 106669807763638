import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  IconButton,
  Box,
  Chip,
  Button,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { uiTexts } from "../../../../../Utils/uiTexts";
import clsx from "clsx";
import "../DataAnalysis/dataanalysis.css";

// ========================================= ANALYSIS MODAL FILTER DROPDOWN ===========================================//

export const AnalysisModalFilterDropdown = ({
  dropdownWidth,
  dropdownData,
  selectedOption,
  label,
  handleChange,
  handleClear,
  handleDeselectAll,
  keyLabel,
  keyId,
  count,
  disableDropdown,
}) => {
  const {
    labelText: { allText, roomTypeNameText },
    buttonText: { deselectAllText, selectAllText },
  } = uiTexts;

  const isAllSelected = selectedOption?.length === dropdownData?.length;

  const showClearIcon = selectedOption?.length > 0 && !isAllSelected;

  const getOption = (option, count) => {
    return `${option} (${count})`;
  };

  const getMenuItemClass = (selectedOption, keyId, name) =>
    clsx({
      "selected-menu-item": selectedOption?.some(
        (selected) => selected[keyId] === name[keyId],
      ),
      "hover-menu-item": true,
    });

  return (
    <FormControl
      sx={{
        m: 1,
        width: dropdownWidth,
        margin: 0,
        "& .MuiOutlinedInput-root": {
          height: 45,
        },
        "& .MuiInputLabel-root": {
          marginTop: "-5px",
        },
      }}
    >
      <InputLabel>{label}</InputLabel>

      <Select
        multiple
        value={selectedOption}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => (
          <Box className="data-analysis-dropdown-gap">
            {isAllSelected ? (
              <Chip label={allText} />
            ) : selected?.length > 1 ? (
              <>
                <Chip
                  label={selected[0][keyLabel]}
                  className="analysis-modal-dropdown-items analysis-modal-chip-text"
                />
                <Chip
                  label={`+${selected?.length - 1}`}
                  className="analysis-modal-dropdown-items analysis-modal-chip-text"
                />
              </>
            ) : (
              selected?.map((value) => (
                <Chip
                  key={value[keyId]}
                  label={value[keyLabel]}
                  className="analysis-modal-dropdown-items analysis-modal-chip-text"
                />
              ))
            )}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
          MenuListProps: {
            sx: {
              padding: 0,
            },
          },
          disableAutoFocusItem: true,
        }}
        disabled={disableDropdown}
      >
        <Box sx={{ mt: 1 }}>
          <Button
            onClick={isAllSelected ? handleDeselectAll : handleClear}
            fullWidth
            className="data-analysis-dropdown-button"
          >
            {isAllSelected ? deselectAllText : selectAllText}
          </Button>
        </Box>

        {dropdownData?.map((name) => (
          <MenuItem
            key={name[keyId]}
            value={name}
            disabled={name[count] === 0 ?? false}
            className={getMenuItemClass(selectedOption, keyId, name)}
          >
            {keyId === !roomTypeNameText ? (
              <Checkbox checked={selectedOption?.includes(name)} />
            ) : (
              <Checkbox
                checked={selectedOption?.some(
                  (selected) => selected[keyId] === name[keyId],
                )}
              />
            )}

            <ListItemText
              primary={
                name[count]
                  ? getOption(name[keyLabel], name[count])
                  : `${name[keyLabel]}`
              }
              className="analysis-modal-dropdown-items"
            />
          </MenuItem>
        ))}
      </Select>

      {showClearIcon && (
        <IconButton
          onClick={handleClear}
          className="data-analysis-filter-dropdown-clear"
        >
          <ClearIcon />
        </IconButton>
      )}
    </FormControl>
  );
};
