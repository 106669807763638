import React, { useState, useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { getInfrastructureAnalysis } from "../../../../../../../Services/DataAnalysisService";
import { InfrastructureUtilizationFilters } from "./InfrastructureUtilizationFilters";
import { InfrastructureUtilizationRhsContent } from "./InfrastructureUtilizationRhsContent";

// =======================================|| INFRASTRUCTURE UTILIZATION ||======================================= //

export const InfrastructureUtilization = () => {
  const {
    labelText: { theoryText, labText },
  } = uiTexts;

  const infrastructureAnalysisData = useSelector(
    (state) => state.dataanalysis_menu.infrastructureAnalysisData,
  );

  const [fetchData, setFetchData] = useState(false);
  const [roomTypes, setRoomTypes] = useState(
    infrastructureAnalysisData?.roomTypeDetails,
  );
  const [selectedRoomTypes, setSelectedRoomTypes] = useState(
    infrastructureAnalysisData?.roomTypeDetails,
  );
  const [selectedBuildings, setSelectedBuildings] = useState(
    infrastructureAnalysisData?.buildingDetails,
  );
  const [searchData, setSearchData] = useState("");
  const [selectedBuildingIds, setSelectedBuildingIds] = useState(
    selectedBuildings?.map((building) => building.buildingId),
  );
  const [selectedRoomTypesName, setSelectedRoomTypesName] = useState(
    selectedRoomTypes?.map((room) => room.roomTypeName),
  );
  const [filteredInfrastructureData, setFilteredInfrastructureData] =
    useState();

  const updateRoomTypeCount = (roomTypeDetails) => {
    const defaultRoomTypes = [theoryText, labText];
    const updatedRoomTypeDetails = defaultRoomTypes?.map((type) => ({
      roomTypeName: type,
      roomTypeCount:
        roomTypeDetails?.find((room) => room.roomTypeName === type)
          ?.roomTypeCount || 0,
    }));

    setRoomTypes(updatedRoomTypeDetails);
    setSelectedRoomTypes(
      roomTypes?.length === selectedRoomTypes?.length
        ? updatedRoomTypeDetails
        : selectedRoomTypes,
    );
  };

  const getFilteredData = async (isUpdateRoomTypeCount) => {
    setFetchData(true);

    const data = {
      buildingId: selectedBuildingIds,
      roomType: selectedRoomTypesName,
    };

    await getInfrastructureAnalysis(data).then(({ data }) => {
      isUpdateRoomTypeCount && updateRoomTypeCount(data?.roomTypeDetails);

      const filteredDetails = data?.details?.filter((room) =>
        room.roomName.toLowerCase().includes(searchData?.toLowerCase()),
      );

      const updatedInfrastructureData = {
        ...data,
        details: filteredDetails,
      };

      setFilteredInfrastructureData(updatedInfrastructureData);
      setFetchData(false);
    });
  };

  useEffect(() => {
    getFilteredData(true);
  }, [selectedBuildingIds]);

  useEffect(() => {
    getFilteredData(false);
  }, [selectedRoomTypesName, searchData]);

  return (
    <Box className="data-analysis-modal-rhs-content">
      <Stack className="data-analysis-filter-part">
        <InfrastructureUtilizationFilters
          buildings={infrastructureAnalysisData?.buildingDetails}
          selectedBuildings={selectedBuildings}
          setSelectedBuildings={setSelectedBuildings}
          roomTypes={roomTypes}
          selectedRoomTypes={selectedRoomTypes}
          setSelectedRoomTypes={setSelectedRoomTypes}
          searchData={searchData}
          setSearchData={setSearchData}
          setSelectedBuildingIds={setSelectedBuildingIds}
          setSelectedRoomTypesName={setSelectedRoomTypesName}
          setRoomTypes={setRoomTypes}
        />

        <InfrastructureUtilizationRhsContent
          fetchData={fetchData}
          filteredInfrastructureData={filteredInfrastructureData}
        />
      </Stack>
    </Box>
  );
};
