// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  infrastructureAnalysisData: [],
  staffWorkLoadAnalysisData: [],
};

// ==============================|| SLICE - DATA ANALYSIS MENU ||============================== //

const dataanalysis_menu = createSlice({
  name: "dataanalysis_menu",
  initialState,
  reducers: {
    InfrastructureAnalysisData(state, action) {
      state.infrastructureAnalysisData = action.payload;
    },

    StaffWorkLoadAnalysisData(state, action) {
      state.staffWorkLoadAnalysisData = action.payload;
    },
  },
});

export default dataanalysis_menu.reducer;

export const { InfrastructureAnalysisData, StaffWorkLoadAnalysisData } =
  dataanalysis_menu.actions;
