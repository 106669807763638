import React from "react";
import { Grid } from "@mui/material";
import TimetableTabs from "../TimetableTabs";
import Warnings from "./Warnings";
import RoomAnalysisWarnings from "./RoomAnalysisWarning";
import "../Timetable.css";

// ==============================|| TIMETABLE MAIN PAGE ||============================== //

const WarningsRoomAnalysisDisplay = ({
  runalgorithm,
  clearResult,
  retrieveDepartmentList,
  retrieveClassList,
  retrieveDay,
  retrieveTime,
}) => {
  return (
    <>
      <Grid sx={{ ml: 1 }} className="timetable">
        <Grid
          sx={{
            mt: 3.5,
            mb: 2,
          }}
        >
          <Warnings
            retrieveDepartmentList={retrieveDepartmentList}
            retrieveClassList={retrieveClassList}
            retrieveDay={retrieveDay}
            retrieveTime={retrieveTime}
          />

          <RoomAnalysisWarnings />
        </Grid>

        <TimetableTabs runalgorithm={runalgorithm} clearResult={clearResult} />
      </Grid>
    </>
  );
};

export default WarningsRoomAnalysisDisplay;
