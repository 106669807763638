import React from "react";
import { TableCell } from "@mui/material";
import "../DataAnalysis/dataanalysis.css";

// =======================================|| ANALYSIS RHS TABLE ITEM ||======================================== //

export const AnalysisRhsTableItem = ({ item, highlight, textWrap }) => {
  return (
    <TableCell
      className={`infrastructure-utilization-table-cell infrastructure-utilization-table-item ${
        textWrap ? "result-analysis-subject-balancing-cell" : ""
      }`}
      sx={{
        color: highlight ? "#FF0000" : "#000",
      }}
    >
      {item}
    </TableCell>
  );
};
