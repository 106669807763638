import React from "react";
import { Table, TableContainer } from "@mui/material";
import { HomePageTableHeader } from "./HomePageTableHeader";
import { HomePageTableItem } from "./HomePageTableItem";
import "../../HomePage.css";

// ========================================|| HOME PAGE TABLE ||========================================= //

export const HomePageTable = ({ setSelectedSemester }) => {
  return (
    <TableContainer className="semester-table-container semester-table-height">
      <Table stickyHeader aria-label="simple table">
        <HomePageTableHeader />

        <HomePageTableItem setSelectedSemester={setSelectedSemester} />
      </Table>
    </TableContainer>
  );
};
