import React from "react";
import { Stack } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { useSelector } from "react-redux";
import { AnalysisModalFilterDropdown } from "../../../AnalysisCommonComponents/AnalysisModalFilterDropdown";
import { AnalysisModalSearchTextField } from "../../../AnalysisCommonComponents/AnalysisModalSearchTextField";

// ===============================|| SUBJECT CONTINUOUS HOURS PREFERENCE MATCH FILTERS ||============================= //

export const SubjectContinuousHoursPreferenceMatchFilters = ({
  departments,
  selectedDepartments,
  setSelectedDepartments,
  setSelectedDepartmentIds,
  classes,
  selectedClasses,
  setSelectedClasses,
  setSelectedClassIds,
  searchData,
  setSearchData,
  setClassDropdownData,
  selectedClassIds,
  selectedDepartmentIds,
}) => {
  const {
    labelText: {
      chooseDepartmentText,
      departmentNameText,
      searchSubjectText,
      classNameText,
      chooseClassText,
      idText,
    },
  } = uiTexts;

  const classList = useSelector((state) => state.class_menu.classList);

  const handleDepartmentChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedDeptIds = value?.map((department) => department.id);
    const filteredClasses = classList?.filter((classItem) =>
      selectedDeptIds?.includes(classItem.departmentId),
    );

    setSelectedDepartments(value);
    setSelectedDepartmentIds(selectedDeptIds);
    setSelectedClasses(filteredClasses);
    // TODO : This is commented for now until the flow is confirmed
    // setSelectedClasses(
    //   selectedClasses?.filter((classItem) =>
    //     selectedDeptIds?.includes(classItem.departmentId),
    //   ),
    // );
    setClassDropdownData(filteredClasses);
  };

  const handleClassChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedClasses(value);
    setSelectedClassIds(value?.map((classes) => classes.id));
  };

  const handleClear = (
    setter1,
    setter2,
    value,
    keyLabel,
    isDepartmentClear,
  ) => {
    setter1(value);
    setter2(value?.map((data) => data[keyLabel]));
    if (isDepartmentClear) {
      setSelectedClasses(classList);
      setClassDropdownData(classList);
    }
  };

  const handleDeselectAll = (
    setter1,
    setter2,
    setter3 = null,
    setter4 = null,
  ) => {
    setter1([]);
    setter2([]);
    if (setter3) setter3([]);
    if (setter4) setter4([]);
  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };

  const disableClassDropdown =
    (selectedClassIds?.length === 0 && classes?.length === 0) ||
    selectedDepartmentIds?.length === 0;

  return (
    <Stack direction="row" className="data-analysis-filter-part">
      <AnalysisModalFilterDropdown
        dropdownWidth={350}
        dropdownData={departments}
        selectedOption={selectedDepartments}
        label={chooseDepartmentText}
        handleChange={handleDepartmentChange}
        handleClear={(e) => {
          e.stopPropagation();
          handleClear(
            setSelectedDepartments,
            setSelectedDepartmentIds,
            departments,
            idText,
            true,
          );
        }}
        handleDeselectAll={(e) => {
          handleDeselectAll(
            setSelectedDepartments,
            setSelectedDepartmentIds,
            setSelectedClasses,
            setSelectedClassIds,
          );
        }}
        keyLabel={departmentNameText}
        keyId={idText}
        count={""}
      />

      <AnalysisModalFilterDropdown
        dropdownWidth={250}
        dropdownData={classes}
        selectedOption={selectedClasses}
        label={chooseClassText}
        handleChange={handleClassChange}
        handleClear={(e) => {
          e.stopPropagation();
          handleClear(
            setSelectedClasses,
            setSelectedClassIds,
            classes,
            idText,
            false,
          );
        }}
        handleDeselectAll={(e) => {
          handleDeselectAll(setSelectedClasses, setSelectedClassIds);
        }}
        keyLabel={classNameText}
        keyId={idText}
        count={""}
        disableDropdown={disableClassDropdown}
      />

      <AnalysisModalSearchTextField
        searchData={searchData}
        handleSearch={handleSearch}
        setSearchData={setSearchData}
        label={searchSubjectText}
      />
    </Stack>
  );
};
