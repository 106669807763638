import React from "react";
import { TableHead, TableRow, TableCell, Typography } from "@mui/material";
import { groupSchedulesAccordionHeader } from "../../../../../../../Utils/globalUtils";

// ==================================|| GROUP SCHEDULES ACCORDION TABLE HEADER ||===================================== //

export const GroupSchedulesAccordionTableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        {groupSchedulesAccordionHeader?.map((text, index) => (
          <TableCell
            className="infrastructure-utilization-table-cell"
            key={index}
          >
            <Typography className="infrastructure-utilization-table-cell infrastructure-utilization-table-cell-count">
              {text}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
