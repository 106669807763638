import React, { useState, useEffect } from "react";
import { Paper, Grid } from "@mui/material";
import AlgorithmWarnings from "../Timetable/Warnings/AlgorithmWarnings";
import { SemesterSelectWarning } from "../../SemesterSelectWarning/SemesterSelectWarning";
import { AddGroupScheduleModal } from "./GroupScheduleModal/AddGroupScheduleModal";
import { GroupScheduleList } from "./GroupScheduleLhsContent/GroupScheduleList";
import { GroupScheduleLhsHeader } from "./GroupScheduleLhsContent/GroupScheduleLhsHeader";
import { GroupScheduleRhsContent } from "./GroupScheduleRhsContent/GroupScheduleRhsContent";
import useRetrieveFunction from "../../../Components/CustomHooks/useRetrieveFunction";
import Loader from "../../Loader/Loader";
import "../Classes/ClassList.css";

// ===================================|| GROUP SCHEDULE MAIN PAGE ||======================================= //

const GroupSchedule = ({
  retrieveCombinedClassList,
  retrieveClassCourseAssignmentList,
  retrieveCourseList,
}) => {
  const { retrieveGroupScheduleData } = useRetrieveFunction();

  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoader(true);
      try {
        await Promise.all([
          retrieveGroupScheduleData(),
          retrieveCourseList(),
          retrieveClassCourseAssignmentList(),
          retrieveCombinedClassList(),
        ]);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setIsLoader(false);
      }
    };

    fetchData();
  }, []);

  const [isOpenAddGroupScheduleModal, setIsOpenAddGroupScheduleModal] =
    useState(false);
  const [selectedGroupSchedule, setSelectedGroupSchedule] = useState();

  const openAddGroupScheduleModal = () => {
    setIsOpenAddGroupScheduleModal(true);
    setSelectedGroupSchedule();
  };

  return (
    <>
      {isLoader ? (
        <Loader />
      ) : (
        <>
          {sessionStorage.getItem("branchName") ? (
            <>
              <div className="display-warnings">
                <AlgorithmWarnings />
              </div>

              <Paper className="paper-padding" sx={{ mt: 0 }}>
                <GroupScheduleLhsHeader
                  openAddGroupScheduleModal={openAddGroupScheduleModal}
                />

                <Grid container spacing={2}>
                  <GroupScheduleList
                    setSelectedGroupSchedule={setSelectedGroupSchedule}
                    selectedGroupSchedule={selectedGroupSchedule}
                  />

                  {selectedGroupSchedule && (
                    <GroupScheduleRhsContent
                      selectedGroupSchedule={selectedGroupSchedule}
                      setSelectedGroupSchedule={setSelectedGroupSchedule}
                    />
                  )}
                </Grid>
              </Paper>

              {isOpenAddGroupScheduleModal && (
                <AddGroupScheduleModal
                  isOpenAddGroupScheduleModal={isOpenAddGroupScheduleModal}
                  setIsOpenAddGroupScheduleModal={
                    setIsOpenAddGroupScheduleModal
                  }
                />
              )}
            </>
          ) : (
            <SemesterSelectWarning />
          )}
        </>
      )}
    </>
  );
};

export default GroupSchedule;
