import React from "react";
import { Stack } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { roundedPercentage } from "../../../../../../../Utils/commonMethods";
import { AnalysisRhsInfo } from "../../../AnalysisCommonComponents/AnalysisRhsInfo";

// =======================================|| STAFF PREFERENCE MATCH RHS INFO ||======================================= //

export const StaffPreferenceMatchRhsInfo = ({
  filteredStaffPreferenceMatchData,
}) => {
  const {
    labelText: {
      staffPreferencesMatchedText,
      staffPreferencesNotMatchedText,
      overAllMatchPercentageText,
    },
  } = uiTexts;

  const {
    staffPreferenceMatched,
    staffPreferenceNotMatched,
    overAllPercentage,
  } = filteredStaffPreferenceMatchData || {};

  return (
    <Stack
      direction="row"
      className="data-analysis-filter-part data-analysis-info-gap"
    >
      <AnalysisRhsInfo
        label={staffPreferencesMatchedText}
        value={staffPreferenceMatched}
      />

      <AnalysisRhsInfo
        label={staffPreferencesNotMatchedText}
        value={staffPreferenceNotMatched}
      />

      <AnalysisRhsInfo
        label={overAllMatchPercentageText}
        value={roundedPercentage(overAllPercentage)}
      />
    </Stack>
  );
};
