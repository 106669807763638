import React from "react";
import { Grid, Table, TableContainer } from "@mui/material";
import { StaffPreferenceMatchTableHeader } from "./StaffPreferenceMatchTableHeader";
import { StaffPreferenceMatchTableItem } from "./StaffPreferenceMatchTableItem";

// =======================================|| STAFF PREFERENCE MATCH TABLE ||======================================== //

export const StaffPreferenceMatchTable = ({
  filteredStaffPreferenceMatchData,
}) => {
  return (
    <Grid item xs={12}>
      <TableContainer className="data-analysis-modal-rhs-content-scroll">
        <Table stickyHeader>
          <StaffPreferenceMatchTableHeader
            filteredStaffPreferenceMatchData={filteredStaffPreferenceMatchData}
          />

          <StaffPreferenceMatchTableItem
            filteredStaffPreferenceMatchData={filteredStaffPreferenceMatchData}
          />
        </Table>
      </TableContainer>
    </Grid>
  );
};
