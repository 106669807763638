import React from "react";
import { TableBody, TableRow } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { sortDataByLocaleCompare } from "../../../../../../../Utils/commonMethods";
import { AnalysisRhsTableItem } from "../../../AnalysisCommonComponents/AnalysisRhsTableItem";
import "../../../DataAnalysis/dataanalysis.css";

// =======================================|| STAFF PREFERENCE MATCH TABLE ITEM ||======================================== //

export const StaffPreferenceMatchTableItem = ({
  filteredStaffPreferenceMatchData,
}) => {
  const {
    buttonText: { yesText, noText },
    labelText: { departmentNameText },
  } = uiTexts;

  const sortedFilteredData = sortDataByLocaleCompare(
    filteredStaffPreferenceMatchData?.staffPreferenceMatchDTOList,
    departmentNameText,
  );

  return (
    <TableBody>
      {sortedFilteredData?.map((detail, index) => (
        <TableRow
          key={index}
          className={
            !detail.matchedOrNot &&
            "infrastructure-utilization-table-row-highlight"
          }
        >
          <AnalysisRhsTableItem
            item={index + 1}
            highlight={!detail.matchedOrNot}
          />

          <AnalysisRhsTableItem
            item={detail.departmentName}
            highlight={!detail.matchedOrNot}
          />

          <AnalysisRhsTableItem
            item={detail.staffName}
            highlight={!detail.matchedOrNot}
          />

          <AnalysisRhsTableItem
            item={detail.subjectName}
            highlight={!detail.matchedOrNot}
          />

          <AnalysisRhsTableItem
            item={detail.dayPreference?.join(", ")}
            highlight={!detail.matchedOrNot}
          />

          <AnalysisRhsTableItem
            item={detail.matchedOrNot ? yesText : noText}
            highlight={!detail.matchedOrNot}
          />
        </TableRow>
      ))}
    </TableBody>
  );
};
