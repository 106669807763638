import React from "react";
import { Box, Typography } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import CircularProgress from "@mui/material/CircularProgress";
import { StaffPreferenceMatchRhsInfo } from "./StaffPreferenceMatchRhsInfo";
import { StaffPreferenceMatchTable } from "./StaffPreferenceMatchTable";

// =======================================|| STAFF PREFERENCE RHS CONTENT ||======================================= //

export const StaffPreferenceRhsContent = ({
  fetchData,
  filteredStaffPreferenceMatchData,
}) => {
  const {
    labelText: { noStaffText },
  } = uiTexts;

  const showRhsContent =
    filteredStaffPreferenceMatchData?.staffPreferenceMatchDTOList?.length > 0;

  const renderContent = () => {
    switch (true) {
      case fetchData:
        return (
          <Box className="data-analysis-rhs-loader">
            <CircularProgress />
          </Box>
        );

      case showRhsContent:
        return (
          <>
            <StaffPreferenceMatchRhsInfo
              filteredStaffPreferenceMatchData={
                filteredStaffPreferenceMatchData
              }
            />

            <StaffPreferenceMatchTable
              filteredStaffPreferenceMatchData={
                filteredStaffPreferenceMatchData
              }
            />
          </>
        );

      default:
        return (
          <Typography className="data-analysis-rhs-data-not-found">
            {noStaffText}
          </Typography>
        );
    }
  };

  return <>{renderContent()}</>;
};
