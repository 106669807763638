import { uiTexts } from "../../../../../../Utils/uiTexts";
import "jspdf-autotable";
import CampusPlannerLogo from "../../../../../../Assets/Images/CampusPlannerLogo.png";
import VVVLogo from "../../../../../../Assets/Images/vvvclogo.png";
import { sortDataByLocaleCompare } from "../../../../../../Utils/commonMethods";

// =================================== INFRASTRUCTURE UTILIZATION REPORT ======================================= //

export const infrastructureUtilizationReport = (doc, infrastructureData) => {
  const {
    labelText: {
      sNoText,
      buildingText,
      roomCapacityText,
      roomTypeText,
      isMultipleSubjectsAllowedText,
      availableSlotsText,
      possibleSubjectsText,
      totalSubjectHoursText,
      canAssignAllSubjectsText,
      roomsWhereAllSubjectsCanBeAssigned,
      roomsWhereAllSubjectsCannotBeAssigned,
      buildingNameText,
    },
    symbolText: { colonText },
    pdfReportText: { vvvCollegeText, infrastructureUtilizationReportText },
    buttonText: { yesText, noText },
  } = uiTexts;

  const {
    allSubjectsCanBeAssigned,
    allSubjectsCannotBeAssigned,
    numberOfBuildings,
    numberOfRooms,
    numberOfRoomType,
  } = infrastructureData || {};

  const sortedInfrastructureData = sortDataByLocaleCompare(
    infrastructureData?.details,
    buildingNameText,
  );

  const width = doc.internal.pageSize.getWidth();

  doc.setFontSize(18);
  doc.addImage(VVVLogo, "PNG", 10, 5, 15, 15);
  doc.addImage(CampusPlannerLogo, "JPG", 320, 5, 25, 15);
  doc.text(vvvCollegeText, width / 2, 15, { align: "center" });
  doc.setFontSize(13);
  doc.text(infrastructureUtilizationReportText, width / 2, 25, {
    align: "center",
  });

  doc.setFontSize(12);
  doc.text(
    roomsWhereAllSubjectsCanBeAssigned + colonText + allSubjectsCanBeAssigned,
    14,
    35,
    { align: "left" },
  );
  doc.text(
    roomsWhereAllSubjectsCannotBeAssigned +
      colonText +
      allSubjectsCannotBeAssigned,
    width - 14,
    35,
    { align: "right" },
  );

  const infrastruturHeaders = [
    sNoText,
    `${buildingText} (${numberOfBuildings})`,
    `${roomCapacityText} (${numberOfRooms})`,
    isMultipleSubjectsAllowedText,
    `${roomTypeText} (${numberOfRoomType})`,
    availableSlotsText,
    possibleSubjectsText,
    totalSubjectHoursText,
    canAssignAllSubjectsText,
  ];

  const infrastructureRows = sortedInfrastructureData?.map((detail, index) => [
    index + 1,
    detail.buildingName,
    `${detail.roomName} (${detail.roomCapacity})`,
    detail.multipleSubjectsAllowedAtOneTime ? yesText : noText,
    detail.roomType,
    detail.availableSlots,
    detail.possibleSubjects,
    detail.totalSubjectHours,
    detail.canAssignAllSubjects ? yesText : noText,
  ]);

  doc.autoTable({
    head: [infrastruturHeaders],
    body: infrastructureRows,
    startY: 40,
    theme: "grid",
    halign: "center",
    valign: "middle",
    styles: { fontSize: 10, minCellHeight: 10 },
  });
};
