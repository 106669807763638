import React from "react";
import { Dialog } from "@mui/material";
import { AddSemesterModalHeader } from "./AddSemesterModalHeader";
import { AddSemesterModalContent } from "./AddSemesterModalContent";
import { AddSemesterModalActionButtons } from "./AddSemesterModalActionButtons";
import "../HomePage.css";

// ====================================|| ADD SEMESTER MODAL ||========================================= //

export const AddSemesterModal = ({
  academicYear,
  semesterType,
  importDataFromSemesterId,
  addSemesterModal,
  setAddSemesterModal,
  handleAcademicYearChange,
  handleSemesterTypeChange,
  handleSaveNewSemester,
  handleCloseSemesterModal,
  addSemesterError,
  handleSemesterChange,
  addNewClick,
}) => {
  const isDisableButton = () => {
    return (
      addSemesterError ||
      semesterType === 0 ||
      academicYear === 0 ||
      importDataFromSemesterId === 0 ||
      addNewClick
    );
  };

  const disableButton = isDisableButton();

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={addSemesterModal}
      className="modal-overlay-background"
    >
      <AddSemesterModalHeader
        handleCloseSemesterModal={handleCloseSemesterModal}
      />

      <AddSemesterModalContent
        importDataFromSemesterId={importDataFromSemesterId}
        handleSemesterChange={handleSemesterChange}
        academicYear={academicYear}
        handleAcademicYearChange={handleAcademicYearChange}
        handleSemesterTypeChange={handleSemesterTypeChange}
        addSemesterError={addSemesterError}
      />

      <AddSemesterModalActionButtons
        disableButton={disableButton}
        handleSaveNewSemester={handleSaveNewSemester}
        handleCloseSemesterModal={handleCloseSemesterModal}
      />
    </Dialog>
  );
};
