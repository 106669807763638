import React from "react";
import { DialogContent } from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../../../../Utils/uiTexts";
import { AddSemesterModalDropdown } from "./AddSemesterModalDropdown";
import { AddSemesterModalRadioButtons } from "./AddSemesterModalRadioButtons";
import "../HomePage.css";

// ====================================|| ADD SEMESTER MODAL ||========================================= //

export const AddSemesterModalContent = ({
  importDataFromSemesterId,
  handleSemesterChange,
  academicYear,
  handleAcademicYearChange,
  handleSemesterTypeChange,
  addSemesterError,
}) => {
  const {
    homePageText: {
      semesterDataToBeImportedText,
      chooseSemesterText,
      idText,
      tenantNameText,
      academicYearText,
      chooseAcademicyearText,
      academicYearCamelCaseText,
    },
  } = uiTexts;

  const { semesterDetails, academicYears } = useSelector(
    (state) => state.login_menu,
  );

  return (
    <>
      <DialogContent>
        <AddSemesterModalDropdown
          xsValue={{ mt: 0 }}
          label={semesterDataToBeImportedText}
          value={importDataFromSemesterId}
          handleChange={handleSemesterChange}
          placeholder={chooseSemesterText}
          dropdownData={semesterDetails}
          keyId={idText}
          keyLabel={tenantNameText}
        />

        <AddSemesterModalDropdown
          xsValue={{ mt: 2 }}
          label={academicYearText}
          value={academicYear}
          handleChange={handleAcademicYearChange}
          placeholder={chooseAcademicyearText}
          dropdownData={academicYears}
          keyId={academicYearCamelCaseText}
          keyLabel={academicYearCamelCaseText}
        />

        <AddSemesterModalRadioButtons
          handleSemesterTypeChange={handleSemesterTypeChange}
        />
      </DialogContent>

      {addSemesterError && (
        <p className="add-new-semester-error">{addSemesterError}</p>
      )}
    </>
  );
};
