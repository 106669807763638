import React from "react";
import { Grid, Table, TableContainer } from "@mui/material";
import { StaffWorkLoadTableHeader } from "./StaffWorkLoadTableHeader";
import { StaffWorkLoadTableItem } from "./StaffWorkLoadTableItem";

// =======================================|| STAFF WORKLOAD TABLE ||======================================== //

export const StaffWorkLoadTable = ({ filteredStaffWorkLoadData }) => {
  return (
    <Grid item xs={12}>
      <TableContainer className="data-analysis-modal-rhs-content-scroll">
        <Table stickyHeader>
          <StaffWorkLoadTableHeader
            filteredStaffWorkLoadData={filteredStaffWorkLoadData}
          />

          <StaffWorkLoadTableItem
            filteredStaffWorkLoadData={filteredStaffWorkLoadData}
          />
        </Table>
      </TableContainer>
    </Grid>
  );
};
