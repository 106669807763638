import auth from "../Utils/auth";
import { Buffer } from "buffer";
import axios from "axios";
import API_URL from "../Utils/http-common";
import { getBranchName } from "../Utils/commonMethods";

const createApiService = () => {
  return axios.create({
    baseURL: API_URL,
  });
};

const apiService = createApiService();
const encodedCredentials = Buffer.from(
  auth.username + ":" + auth.password,
).toString("base64");

const resultAnalysis = "resultAnalysis";

// ========================================|| RESULT ANALYSIS SERVICE ||======================================== //

export const getOverAllPercentage = () => {
  return apiService.get(`/${resultAnalysis}/overAllPercentage`, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getStaffPreferenceMatch = (data) => {
  return apiService.post(`/${resultAnalysis}/staffPreferenceMatch`, data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getStaffWorkLoadBalancing = (data) => {
  return apiService.post(`/${resultAnalysis}/staffWorkLoad`, data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getSubjectBalancing = (data) => {
  return apiService.post(`/${resultAnalysis}/subjectBalancing`, data, {
    headers: {
      "Content-type": "application/json",
      branch: getBranchName(),
      Authorization: "Basic " + encodedCredentials,
    },
  });
};

export const getSubjectContinuousHoursPreferenceMatch = (data) => {
  return apiService.post(
    `/${resultAnalysis}/subjectContinuousHoursPreference`,
    data,
    {
      headers: {
        "Content-type": "application/json",
        branch: getBranchName(),
        Authorization: "Basic " + encodedCredentials,
      },
    },
  );
};

export const getGroupSchedules = () => {
  return apiService.post(
    `/${resultAnalysis}/groupScheduleMatch`,
    {},
    {
      headers: {
        "Content-type": "application/json",
        branch: getBranchName(),
        Authorization: "Basic " + encodedCredentials,
      },
    },
  );
};
