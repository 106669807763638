import React from "react";
import { TableHead, TableRow, TableCell, Typography } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { subjectContinuousHoursPreferenceMatchHeader } from "../../../../../../../Utils/globalUtils";
import { getUniqueCount } from "../../../../../../../Utils/commonMethods";

// ===============================|| SUBJECT CONTINUOUS HOURS PREFERENCE MATCH TABLE HEADER ||========================= //

export const SubjectContinuousHoursPreferenceMatchTableHeader = ({
  filteredSubjectContinuousHoursPreferenceMatchData,
}) => {
  const {
    labelText: {
      departmentText,
      classText,
      subjectText,
      departmentNameText,
      classNameText,
      subjectNameText,
    },
  } = uiTexts;

  const uniqueDepartmentCount = getUniqueCount(
    filteredSubjectContinuousHoursPreferenceMatchData,
    departmentNameText,
  );
  const uniqueClassCount = getUniqueCount(
    filteredSubjectContinuousHoursPreferenceMatchData,
    classNameText,
  );
  const uniqueSubjectCount = getUniqueCount(
    filteredSubjectContinuousHoursPreferenceMatchData,
    subjectNameText,
  );

  const getCount = (text) => {
    switch (text) {
      case departmentText:
        return `(${uniqueDepartmentCount})`;
      case classText:
        return `(${uniqueClassCount})`;
      case subjectText:
        return `(${uniqueSubjectCount})`;
      default:
        return "";
    }
  };

  return (
    <TableHead>
      <TableRow>
        {subjectContinuousHoursPreferenceMatchHeader?.map((text, index) => (
          <TableCell
            className="infrastructure-utilization-table-cell"
            key={index}
          >
            <Typography className="infrastructure-utilization-table-cell infrastructure-utilization-table-cell-count">
              {text} {getCount(text)}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
