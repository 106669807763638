import React from "react";
import { TableBody, TableRow } from "@mui/material";
import {
  roundedPercentage,
  sortDataByLocaleCompare,
} from "../../../../../../../Utils/commonMethods";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { AnalysisRhsTableItem } from "../../../AnalysisCommonComponents/AnalysisRhsTableItem";
import "../../../DataAnalysis/dataanalysis.css";

// =======================================|| SUBJECT BALANCING TABLE ITEM ||======================================== //

export const SubjectBalancingTableItem = ({ filteredSubjectBalancingData }) => {
  const {
    labelText: { departmentNameText },
  } = uiTexts;

  const sortedFilteredData = sortDataByLocaleCompare(
    filteredSubjectBalancingData,
    departmentNameText,
  );

  return (
    <TableBody>
      {sortedFilteredData?.map((detail, index) => (
        <TableRow key={index}>
          <AnalysisRhsTableItem item={index + 1} textWrap={true} />

          <AnalysisRhsTableItem item={detail.departmentName} textWrap={true} />

          <AnalysisRhsTableItem item={detail.className} textWrap={true} />

          <AnalysisRhsTableItem item={detail.subject} textWrap={true} />

          <AnalysisRhsTableItem item={detail.subjectType} textWrap={true} />

          <AnalysisRhsTableItem item={detail.numberOfHours} textWrap={true} />

          <AnalysisRhsTableItem
            item={detail.numberOfDaysAllotted}
            textWrap={true}
          />

          <AnalysisRhsTableItem
            item={roundedPercentage(detail.subjectBalancePercentage)}
            textWrap={true}
          />
        </TableRow>
      ))}
    </TableBody>
  );
};
