import React, { useState } from "react";
import { Modal, Grid, Box } from "@mui/material";
import { uiTexts } from "../../../../../../Utils/uiTexts";
import Loader from "../../../../../Loader/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import { AnalysisModalHeader } from "../../../Analysis/AnalysisCommonComponents/AnalysisModalHeader";
import { DataAnalysisModalLhs } from "./DataAnalysisModalContent/DataAnalysisModalLhs";
import { DataAnalysisModalRhs } from "./DataAnalysisModalContent/DataAnalysisModalRhs";
import "../../../../../Dashboard/ShortcutModal/shortcutmodal.css";
import "../dataanalysis.css";

// ====================================== DATA ANALYSIS MODAL ===================================== //

export const DataAnalysisModal = ({ closeClick, isLoader }) => {
  const {
    alertTitleText: { dataAnalysisText },
  } = uiTexts;

  const [showSelectedOption, setShowSelectedOption] = useState(1);
  const [fetchData, setFetchData] = useState(false);

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="shortcut-modal-overlay-background"
    >
      {isLoader ? (
        <Loader />
      ) : (
        <Grid item xs={8.7} className="shortcut-modal">
          <div className="shortcut-modal-content data-analysis-modal-width">
            <Grid item className="shortcut-modal-content-height">
              <AnalysisModalHeader
                headerText={dataAnalysisText}
                closeClick={closeClick}
              />

              <Grid container className="data-analysis-modal-content-height">
                <DataAnalysisModalLhs
                  showSelectedOption={showSelectedOption}
                  setShowSelectedOption={setShowSelectedOption}
                  setFetchData={setFetchData}
                />

                {fetchData ? (
                  <Box className="data-analysis-rhs-loader">
                    <CircularProgress />
                  </Box>
                ) : (
                  <DataAnalysisModalRhs
                    showSelectedOption={showSelectedOption}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      )}
    </Modal>
  );
};
