import React from "react";
import { Stack } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { roundedPercentage } from "../../../../../../../Utils/commonMethods";
import { AnalysisRhsInfo } from "../../../AnalysisCommonComponents/AnalysisRhsInfo";

// ====================================|| GROUP SCHEDULES RHS INFO ||======================================== //

export const GroupSchedulesRhsInfo = ({ filteredGroupSchedulesData }) => {
  const {
    labelText: {
      matchedGroupSchedulesText,
      unmatchedGroupSchedulesText,
      overAllMatchPercentageText,
    },
    analysisModalListText: { groupSchedulesText },
  } = uiTexts;

  const { matchedGroupSchedules, notMatchedGroupSchedules, overAllPercentage } =
    filteredGroupSchedulesData || {};

  return (
    <Stack direction="row" className="data-analysis-filter-part">
      <AnalysisRhsInfo
        label={matchedGroupSchedulesText}
        value={matchedGroupSchedules}
      />

      <AnalysisRhsInfo
        label={unmatchedGroupSchedulesText}
        value={notMatchedGroupSchedules}
      />

      <AnalysisRhsInfo
        label={groupSchedulesText}
        value={filteredGroupSchedulesData?.groupScheduleResultDTOList?.length}
      />

      <AnalysisRhsInfo
        label={overAllMatchPercentageText}
        value={roundedPercentage(overAllPercentage)}
      />
    </Stack>
  );
};
