import React from "react";
import { DialogActions, Button, Stack } from "@mui/material";
import { uiTexts } from "../../../../Utils/uiTexts";
import "../HomePage.css";

// ====================================|| ADD SEMESTER MODAL ACTION BUTTONS ||========================================= //

export const AddSemesterModalActionButtons = ({
  disableButton,
  handleSaveNewSemester,
  handleCloseSemesterModal,
}) => {
  const {
    buttonText: { cancelText, saveText },
  } = uiTexts;

  const getButtonClass = (isDisabled) =>
    `semester-save-button ${
      isDisabled
        ? "semester-save-button-disabled-background"
        : "semester-save-button-background"
    }`;

  return (
    <DialogActions>
      <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
        <Button
          variant="contained"
          className={getButtonClass(disableButton)}
          disabled={disableButton}
          onClick={handleSaveNewSemester}
        >
          {saveText}
        </Button>

        <Button
          variant="outlined"
          className="semester-cancel-button"
          onClick={handleCloseSemesterModal}
        >
          {cancelText}
        </Button>
      </Stack>
    </DialogActions>
  );
};
