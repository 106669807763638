import { uiTexts } from "../../../../../../Utils/uiTexts";
import "jspdf-autotable";
import CampusPlannerLogo from "../../../../../../Assets/Images/CampusPlannerLogo.png";
import VVVLogo from "../../../../../../Assets/Images/vvvclogo.png";
import {
  getUniqueCount,
  sortDataByLocaleCompare,
} from "../../../../../../Utils/commonMethods";

// ======================== || SUBJECT CONTINUOUS HOURS PREFERENCE MATCH REPORT || ===================== //

export const subjectContinuoisHoursPreferenceMatchReport = (
  doc,
  subjectContinuousHoursPreferenceMatchData,
) => {
  const {
    labelText: {
      sNoText,
      departmentText,
      classText,
      subjectText,
      subjectHoursText,
      hourDistributionText,
      isContinuousText,
      continuousHourPreferencesText,
      nonContinuousHoursPreferencesText,
      departmentNameText,
      classNameText,
      subjectNameText,
    },
    symbolText: { colonText },
    pdfReportText: {
      vvvCollegeText,
      subjectContinuousHoursPreferenceMatchReportText,
    },
    buttonText: { yesText, noText },
  } = uiTexts;

  const sortedSubjectContinuousHoursPreferenceMatchData =
    sortDataByLocaleCompare(
      subjectContinuousHoursPreferenceMatchData,
      departmentNameText,
    );

  doc.addPage();
  const newStartY = 20;
  const width = doc.internal.pageSize.getWidth();

  doc.setFontSize(18);
  doc.addImage(VVVLogo, "PNG", 10, newStartY - 15, 15, 15);
  doc.addImage(CampusPlannerLogo, "JPG", 320, newStartY - 15, 25, 15);
  doc.text(vvvCollegeText, width / 2, newStartY - 5, { align: "center" });
  doc.setFontSize(13);
  doc.text(
    subjectContinuousHoursPreferenceMatchReportText,
    width / 2,
    newStartY + 5,
    {
      align: "center",
    },
  );

  const efficiencyCount = subjectContinuousHoursPreferenceMatchData?.reduce(
    (acc, item) => {
      item.efficiencyMatched ? acc.trueCount++ : acc.falseCount++;
      return acc;
    },
    { trueCount: 0, falseCount: 0 },
  );

  doc.setFontSize(12);
  doc.text(
    continuousHourPreferencesText + colonText + efficiencyCount?.trueCount,
    14,
    newStartY + 15,
    { align: "left" },
  );
  doc.text(
    nonContinuousHoursPreferencesText + colonText + efficiencyCount?.falseCount,
    width - 14,
    newStartY + 15,
    { align: "right" },
  );

  const uniqueDepartmentCount = getUniqueCount(
    subjectContinuousHoursPreferenceMatchData,
    departmentNameText,
  );
  const uniqueClassCount = getUniqueCount(
    subjectContinuousHoursPreferenceMatchData,
    classNameText,
  );
  const uniqueSubjectCount = getUniqueCount(
    subjectContinuousHoursPreferenceMatchData,
    subjectNameText,
  );

  const subjectContinuousMatchHeaders = [
    sNoText,
    `${departmentText} (${uniqueDepartmentCount})`,
    `${classText} (${uniqueClassCount})`,
    `${subjectText} (${uniqueSubjectCount})`,
    subjectHoursText,
    hourDistributionText,
    isContinuousText,
  ];

  const getDayAndTime = (resultDayAndTime) => {
    return resultDayAndTime
      .split(" | ") // Split by " | "
      .map((entry) => entry.replace("Time", "- Time")) // Replace "Time" with "- Time"
      .join("\n"); // Join with new lines for PDF formatting
  };

  const subjectContinuousMatchRows =
    sortedSubjectContinuousHoursPreferenceMatchData?.map((detail, index) => [
      index + 1,
      detail.departmentName,
      detail.className,
      detail.subjectName,
      detail.subjectHours,
      getDayAndTime(detail.resultDayAndTime),
      detail.efficiencyMatched ? yesText : noText,
    ]);

  doc.autoTable({
    head: [subjectContinuousMatchHeaders],
    body: subjectContinuousMatchRows,
    startY: newStartY + 25,
    theme: "grid",
    halign: "center",
    valign: "middle",
    styles: { fontSize: 10, minCellHeight: 10 },
  });
};
