import React from "react";
import { TableHead, TableRow, TableCell, Typography } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import {
  insertLineBreakBefore,
  getUniqueCount,
} from "../../../../../../../Utils/commonMethods";
import { staffWorkLoadBalancingHeader } from "../../../../../../../Utils/globalUtils";

// ==================================|| STAFF WORKLOAD BALANCING TABLE HEADER ||===================================== //

export const StaffWorkLoadBalancingTableHeader = ({
  filteredStaffWorkLoadBalancingData,
}) => {
  const {
    labelText: {
      departmentText,
      staffText,
      teachingHoursWithoutABreakExcludingContinuousLabHoursText,
      excludingContinuousLabHoursText,
      staffNameText,
      departmentNameText,
    },
  } = uiTexts;

  const staffWorkLoadBalanceDTOSList =
    filteredStaffWorkLoadBalancingData?.staffWorkLoadBalanceDTOS || [];

  const uniqueDepartmentCount = getUniqueCount(
    staffWorkLoadBalanceDTOSList,
    departmentNameText,
  );
  const uniqueStaffCount = getUniqueCount(
    staffWorkLoadBalanceDTOSList,
    staffNameText,
  );

  const getCount = (text) => {
    switch (text) {
      case departmentText:
        return `(${uniqueDepartmentCount})`;
      case staffText:
        return `(${uniqueStaffCount})`;
      default:
        return "";
    }
  };

  const renderHeaderText = (text) => {
    if (text === teachingHoursWithoutABreakExcludingContinuousLabHoursText) {
      return insertLineBreakBefore(
        teachingHoursWithoutABreakExcludingContinuousLabHoursText,
        excludingContinuousLabHoursText,
      );
    }
    return (
      <>
        {text} {getCount(text)}
      </>
    );
  };

  return (
    <TableHead>
      <TableRow>
        {staffWorkLoadBalancingHeader?.map((text, index) => (
          <TableCell
            className="infrastructure-utilization-table-cell"
            key={index}
          >
            <Typography className="infrastructure-utilization-table-cell infrastructure-utilization-table-cell-count">
              {renderHeaderText(text)}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
