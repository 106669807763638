import { uiTexts } from "../../../../../../Utils/uiTexts";
import "jspdf-autotable";
import CampusPlannerLogo from "../../../../../../Assets/Images/CampusPlannerLogo.png";
import VVVLogo from "../../../../../../Assets/Images/vvvclogo.png";
import {
  roundedPercentage,
  getDay,
  getTime,
} from "../../../../../../Utils/commonMethods";

// ====================================== || GROUP SCHEDULES MATCH REPORT || ======================================= //

export const groupSchedulesMatchReport = (
  doc,
  groupSchedulesData,
  dayList,
  timeList,
) => {
  const {
    labelText: {
      sNoText,
      matchedGroupSchedulesText,
      unmatchedGroupSchedulesText,
      overAllMatchPercentageText,
      groupScheduleNameText,
      subjectHoursText,
      subjectsText,
      dayAndTimeSlotsText,
    },
    analysisModalListText: { groupSchedulesText },
    symbolText: { colonText },
    pdfReportText: { vvvCollegeText, groupSchedulesReportText },
  } = uiTexts;

  const { matchedGroupSchedules, notMatchedGroupSchedules, overAllPercentage } =
    groupSchedulesData || {};

  const groupScheduleResultDTOList =
    groupSchedulesData?.groupScheduleResultDTOList;

  doc.addPage();
  const newStartY = 20;
  const width = doc.internal.pageSize.getWidth();

  doc.setFontSize(18);
  doc.addImage(VVVLogo, "PNG", 10, newStartY - 15, 15, 15);
  doc.addImage(CampusPlannerLogo, "JPG", 320, newStartY - 15, 25, 15);
  doc.text(vvvCollegeText, width / 2, newStartY - 5, { align: "center" });
  doc.setFontSize(13);
  doc.text(groupSchedulesReportText, width / 2, newStartY + 5, {
    align: "center",
  });

  doc.setFontSize(12);
  const firstTextY = newStartY + 15;
  const secondTextY = firstTextY;
  const thirdTextY = firstTextY + 8;
  const fourthTextY = secondTextY + 8;

  doc.text(
    matchedGroupSchedulesText + colonText + matchedGroupSchedules,
    14,
    firstTextY,
    { align: "left" },
  );
  doc.text(
    unmatchedGroupSchedulesText + colonText + notMatchedGroupSchedules,
    14,
    thirdTextY,
    { align: "left" },
  );
  doc.text(
    groupSchedulesText + colonText + groupScheduleResultDTOList?.length,
    width - 14,
    secondTextY,
    { align: "right" },
  );
  doc.text(
    overAllMatchPercentageText +
      colonText +
      roundedPercentage(overAllPercentage),
    width - 14,
    fourthTextY,
    { align: "right" },
  );

  const groupSchedulesHeaders = [
    sNoText,
    groupScheduleNameText,
    subjectHoursText,
    subjectsText,
    dayAndTimeSlotsText,
  ];

  const groupSchedulesRows = [];
  let serialNumber = 1;

  groupScheduleResultDTOList?.forEach((detail) => {
    let baseRow = [serialNumber++, detail.groupName, detail.courseHours];

    let subjectTimeRows = [];
    let courseIndex = 1;

    // Matched Courses - Single Row
    if (detail.matchedCourses?.courseNames?.length) {
      let matchedSubjects = detail.matchedCourses.courseNames
        ?.map((course) => `${courseIndex++}. ${course}`)
        .join("\n");

      let matchedTimeSlots = detail.matchedCourses.matchedDayAndTimeDTOS
        ?.map(
          (slot) =>
            `${getDay(slot.dayId, dayList)} - ${getTime(
              slot.timeId,
              timeList,
            )}`,
        )
        .join("\n");

      subjectTimeRows?.push([matchedSubjects, matchedTimeSlots]);
    }

    // Unmatched Courses - Separate Rows (Only Subjects and Time Slots Split)
    detail.unMatchedCourses?.forEach((course) => {
      let timeSlots = course.unMatchedDayAndTimeDTOS
        ?.map(
          (slot) =>
            `${getDay(slot.dayId, dayList)} - ${getTime(
              slot.timeId,
              timeList,
            )}`,
        )
        .join("\n");

      subjectTimeRows?.push([
        `${courseIndex++}. ${course.courseName}`,
        timeSlots,
      ]);
    });

    // Merge Group Name & Subject Hours for the first row only
    subjectTimeRows?.forEach((row, i) => {
      if (i === 0) {
        groupSchedulesRows?.push([...baseRow, row[0], row[1]]);
      } else {
        groupSchedulesRows?.push(["", "", "", row[0], row[1]]);
      }
    });
  });

  doc.autoTable({
    head: [groupSchedulesHeaders],
    body: groupSchedulesRows,
    startY: newStartY + 25,
    theme: "grid",
    halign: "center",
    valign: "middle",
    styles: { fontSize: 10, minCellHeight: 10 },
  });
};
