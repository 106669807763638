import { useDispatch } from "react-redux";
import { GroupScheduleData } from "../../Store/Reducers/groupschedule_menu";
import { OverAllPercentageData } from "../../Store/Reducers/resultanalysis_menu";
import { getGroupScheduleAll } from "../../Services/GroupScheduleService";
import { getOverAllPercentage } from "../../Services/ResultAnalysisService";

// ================================== USE RETRIEVE FUNCTION ======================================== //

export default function useRetrieveFunctions() {
  const dispatch = useDispatch();

  const retrieveGroupScheduleData = async () => {
    await getGroupScheduleAll().then(({ data }) => {
      dispatch(GroupScheduleData(data));
    });
  };

  const retrieveOverAllPercentageData = async () => {
    await getOverAllPercentage().then(({ data }) => {
      dispatch(OverAllPercentageData(data));
    });
  };

  return { retrieveGroupScheduleData, retrieveOverAllPercentageData };
}
