import React from "react";
import "../../resultanalysis.css";
import { Box, Grid, Table, TableContainer } from "@mui/material";
import { OverallPercentageTableHeader } from "./OverallPercentageTableHeader";
import { OverallPercentageTableItem } from "./OverallPercentageTableItem";

// =======================================|| OVERALL PERCENTAGE ||======================================= //

export const OverallPercentage = () => {
  return (
    <Box className="data-analysis-modal-rhs-content">
      <Grid item xs={8} className="overall-percentage-rhs-margin">
        <TableContainer className="overall-percentage-rhs-content-scroll">
          <Table stickyHeader>
            <OverallPercentageTableHeader />

            <OverallPercentageTableItem />
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  );
};
