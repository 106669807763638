import React, { useState } from "react";
import { AlertComponent } from "../../../../CommonComponents/AlertComponent";
import { uiTexts } from "../../../../../Utils/uiTexts";
import { useDispatch } from "react-redux";
import { InfrastructureAnalysisData } from "../../../../../Store/Reducers/dataanalysis_menu";
import {
  getInfrastructureAnalysis,
  getStaffWorkLoadAnalysis,
} from "../../../../../Services/DataAnalysisService";
import { DataAnalysisModal } from "./DataAnalysisModal/DataAnalysisModal";
import { dataAnalysisReport } from "../DataAnalysis/DataAnalysisDownload/DataAnalysisReport";
import "../../Timetable.css";

// =====================================|| DATA ANALYSIS ||======================================== //

export const DataAnalysis = () => {
  const {
    alertTitleText: { dataAnalysisText },
  } = uiTexts;

  const dispatch = useDispatch();

  const [openDataAnalysisModal, setOpenDataAnalysisModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const fetchData = () => {
    setIsLoader(true);

    const infrastructureData = { buildingId: null, roomType: null };

    getInfrastructureAnalysis(infrastructureData)
      .then(({ data }) => {
        dispatch(InfrastructureAnalysisData(data));
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const handleOpenDataAnalysisModal = () => {
    fetchData();
    setOpenDataAnalysisModal(true);
  };

  const downLoadClick = () => {
    const infrastructureData = { buildingId: null, roomType: null };
    const staffWorkLoadData = { departmentIds: null };

    Promise.all([
      getInfrastructureAnalysis(infrastructureData),
      getStaffWorkLoadAnalysis(staffWorkLoadData),
    ]).then(([infrastructureResponse, staffWorkLoadResponse]) => {
      dataAnalysisReport(
        infrastructureResponse.data,
        staffWorkLoadResponse.data,
      );
    });
  };

  return (
    <>
      <AlertComponent
        severity="info"
        alertCss="import-warning data-analysis-warning"
        title={dataAnalysisText}
        viewClick={handleOpenDataAnalysisModal}
        downloadClick={downLoadClick}
      />

      {openDataAnalysisModal && (
        <DataAnalysisModal
          closeClick={() => setOpenDataAnalysisModal(false)}
          isLoader={isLoader}
        />
      )}
    </>
  );
};
