import React from "react";
import { Stack } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { AnalysisRhsInfo } from "../../../AnalysisCommonComponents/AnalysisRhsInfo";

// =======================================|| STAFF WORKLOAD BALANCING RHS INFO ||======================================= //

export const StaffWorkLoadBalancingRhsInfo = ({
  filteredStaffWorkLoadBalancingData,
}) => {
  const {
    labelText: {
      staffAssignedDuringTheFirstHourAcrossAllDaysText,
      staffAssignedDuringTheLastHourAcrossAllDaysText,
      noOfStaffWithNoAssignedHoursOnAnyDayText,
    },
  } = uiTexts;

  const {
    firstHourAllAssignedStaffCount,
    lastHourAllAssignedStaffCount,
    noHoursAssignedOnAnyDayStaffCount,
  } = filteredStaffWorkLoadBalancingData?.hoursBasedDTO || {};

  return (
    <Stack direction="row" className="data-analysis-filter-part">
      <AnalysisRhsInfo
        label={staffAssignedDuringTheFirstHourAcrossAllDaysText}
        value={firstHourAllAssignedStaffCount}
      />

      <AnalysisRhsInfo
        label={staffAssignedDuringTheLastHourAcrossAllDaysText}
        value={lastHourAllAssignedStaffCount}
      />

      <AnalysisRhsInfo
        label={noOfStaffWithNoAssignedHoursOnAnyDayText}
        value={noHoursAssignedOnAnyDayStaffCount}
      />
    </Stack>
  );
};
