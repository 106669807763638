import React, { useState } from "react";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Button,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoginService from "../../Services/LoginService";
import { LoginDetails, SemesterDetails } from "../../Store/Reducers/login_menu";
import { setUserSession } from "../../Utils/commonMethods";
import { uiTexts } from "../../Utils/uiTexts";
import "./loginstyle.css";

// ===================================|| LOGIN  ACTIONS (TEXTFIELDS AND BUTTON) ||============================== //

export const LoginActions = () => {
  const {
    loginText: {
      usernameAndPasswordIsRequiredText,
      usernameIsRequiredText,
      passwordIsRequiredText,
      noMoreDataText,
      invalidCredentialsText,
      somethingWentWrongText,
      userNameText,
      passwordText,
      loginText,
      usernameLowerCaseText,
      enterUserNameText,
      passwordLowerCaseText,
      enterPasswordText,
      text,
    },
  } = uiTexts;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [uservalue, setUserValue] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserValue({ ...uservalue, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { username, password } = uservalue || {};

    if (!username && !password) {
      return setError(usernameAndPasswordIsRequiredText);
    }
    if (!username) {
      return setError(usernameIsRequiredText);
    }
    if (!password) {
      return setError(passwordIsRequiredText);
    }

    const loginData = { username, password };

    LoginService.createLogin(loginData)
      .then(({ data, status }) => {
        if (status !== 200) return setError(noMoreDataText);

        dispatch(LoginDetails(data));
        setUserSession(data.username);

        LoginService.getSemester().then(({ data }) =>
          dispatch(SemesterDetails(data)),
        );

        navigate("/home");
      })
      .catch((error) => {
        const errorStatus = error.response?.status;
        setError(
          errorStatus === 401 ? invalidCredentialsText : somethingWentWrongText,
        );
      });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)} className="login-action-content">
      <Typography className="username-text">{userNameText}</Typography>
      <OutlinedInput
        className="username-textbox"
        margin="normal"
        required
        id={usernameLowerCaseText}
        name={usernameLowerCaseText}
        placeholder={enterUserNameText}
        value={uservalue?.username}
        onChange={(e) => handleChange(e)}
      />

      <Typography className="username-text login-action-content-gap">
        {passwordText}
      </Typography>
      <OutlinedInput
        className="password-textbox"
        required
        name={passwordLowerCaseText}
        placeholder={enterPasswordText}
        type={showPassword ? text : passwordLowerCaseText}
        id={passwordLowerCaseText}
        autoComplete="current-password"
        value={uservalue?.password}
        onChange={(e) => handleChange(e)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        }
      />

      <br></br>
      {error && <p className="error-text login-action-content-gap">{error}</p>}

      <Button
        type="submit"
        className="login-submit"
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        {loginText}
      </Button>
    </form>
  );
};
