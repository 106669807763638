import React, { useState, useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { getGroupSchedules } from "../../../../../../../Services/ResultAnalysisService";
import { AnalysisModalSearchTextField } from "../../../AnalysisCommonComponents/AnalysisModalSearchTextField";
import { GroupSchedulesRhsContent } from "./GroupSchedulesRhsContent";

// ============================================|| GROUP SCHEDULES ||========================================= //

export const GroupSchedules = () => {
  const {
    labelText: { searchGroupScheduleText },
  } = uiTexts;

  const [fetchData, setFetchData] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [filteredGroupSchedulesData, setFilteredGroupScheduleData] = useState();

  const getFilteredData = async () => {
    setFetchData(true);

    await getGroupSchedules().then(({ data }) => {
      const filteredDetails = data?.groupScheduleResultDTOList?.filter(
        (schedule) =>
          schedule.groupName.toLowerCase().includes(searchData?.toLowerCase()),
      );

      const updatedGroupScheduleData = {
        ...data,
        groupScheduleResultDTOList: filteredDetails,
      };

      setFilteredGroupScheduleData(updatedGroupScheduleData);
      setFetchData(false);
    });
  };

  useEffect(() => {
    getFilteredData();
  }, [searchData]);

  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <Box className="data-analysis-modal-rhs-content">
      <Stack className="data-analysis-filter-part">
        <AnalysisModalSearchTextField
          searchData={searchData}
          handleSearch={handleSearch}
          setSearchData={setSearchData}
          label={searchGroupScheduleText}
        />

        <GroupSchedulesRhsContent
          fetchData={fetchData}
          filteredGroupSchedulesData={filteredGroupSchedulesData}
        />
      </Stack>
    </Box>
  );
};
