import React, { useState, useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { getSubjectContinuousHoursPreferenceMatch } from "../../../../../../../Services/ResultAnalysisService";
import { SubjectContinuousHoursPreferenceMatchFilters } from "./SubjectContinuousHoursPreferenceMatchFilters";
import { SubjectContinuousHoursPreferenceMatchRhsContent } from "./SubjectContinuousHoursPreferenceMatchRhsContent";

// ============================|| SUBJECT CONTINUOUS HOURS PREFERENCE MATCH ||============================== //

export const SubjectContinuousHoursPreferenceMatch = () => {
  const departmentList = useSelector(
    (state) => state.infrastructure_menu.departmentList,
  );
  const classList = useSelector((state) => state.class_menu.classList);

  const [fetchData, setFetchData] = useState(false);
  const [selectedDepartments, setSelectedDepartments] =
    useState(departmentList);
  const [classDropdownData, setClassDropdownData] = useState(classList);
  const [selectedClasses, setSelectedClasses] = useState(classList);
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState();
  const [selectedClassIds, setSelectedClassIds] = useState();
  const [searchData, setSearchData] = useState("");
  const [
    filteredSubjectContinuousHoursPreferenceMatchData,
    setFilteredSubjectContinuousHoursPreferenceMatchData,
  ] = useState();

  useEffect(() => {
    setSelectedDepartmentIds(
      selectedDepartments?.map((department) => department.id),
    );
    setSelectedClassIds(selectedClasses?.map((classes) => classes.id));
  }, [selectedDepartments, selectedClasses]);

  const getFilteredData = async () => {
    setFetchData(true);

    const subjectContinuousHoursData = {
      departmentIds: selectedDepartmentIds,
      classIds: selectedClassIds,
    };

    await getSubjectContinuousHoursPreferenceMatch(
      subjectContinuousHoursData,
    ).then(({ data }) => {
      const filteredDetails = data?.filter((subjects) =>
        subjects.subjectName.toLowerCase().includes(searchData?.toLowerCase()),
      );

      setFilteredSubjectContinuousHoursPreferenceMatchData(filteredDetails);
      setFetchData(false);
    });
  };

  useEffect(() => {
    getFilteredData();
  }, [selectedDepartmentIds, selectedClassIds, searchData]);

  return (
    <Box className="data-analysis-modal-rhs-content">
      <Stack className="data-analysis-filter-part">
        <SubjectContinuousHoursPreferenceMatchFilters
          departments={departmentList}
          selectedDepartments={selectedDepartments}
          setSelectedDepartments={setSelectedDepartments}
          setSelectedDepartmentIds={setSelectedDepartmentIds}
          classes={classDropdownData}
          selectedClasses={selectedClasses}
          setSelectedClasses={setSelectedClasses}
          setSelectedClassIds={setSelectedClassIds}
          searchData={searchData}
          setSearchData={setSearchData}
          setClassDropdownData={setClassDropdownData}
          selectedClassIds={selectedClassIds}
          selectedDepartmentIds={selectedDepartmentIds}
        />

        <SubjectContinuousHoursPreferenceMatchRhsContent
          fetchData={fetchData}
          filteredSubjectContinuousHoursPreferenceMatchData={
            filteredSubjectContinuousHoursPreferenceMatchData
          }
        />
      </Stack>
    </Box>
  );
};
