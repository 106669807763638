import React from "react";
import { DialogTitle, IconButton, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { uiTexts } from "../../../../Utils/uiTexts";
import "../HomePage.css";

// ======================================|| ADD SEMESTER MODAL HEADER ||=========================================== //

export const AddSemesterModalHeader = ({ handleCloseSemesterModal }) => {
  const {
    homePageText: { addNewSemesterText },
  } = uiTexts;

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {addNewSemesterText}
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleCloseSemesterModal}
        className="semester-modal-close-icon"
      >
        <CloseIcon />
      </IconButton>

      <Divider />
    </>
  );
};
