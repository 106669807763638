import React from "react";
import { Stack } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { AnalysisModalFilterDropdown } from "../../../AnalysisCommonComponents/AnalysisModalFilterDropdown";
import { AnalysisModalSearchTextField } from "../../../AnalysisCommonComponents/AnalysisModalSearchTextField";

// =======================================|| STAFF PREFERENCE MATCH FILTERS ||======================================= //

export const StaffPreferenceMatchFilters = ({
  departments,
  selectedDepartments,
  setSelectedDepartments,
  setSelectedDepartmentIds,
  searchData,
  setSearchData,
}) => {
  const {
    labelText: {
      chooseDepartmentText,
      departmentNameText,
      searchStaffText,
      idText,
    },
  } = uiTexts;

  const handleDepartmentChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDepartments(value);
    setSelectedDepartmentIds(value?.map((department) => department.id));
  };

  const handleClear = (setter1, setter2, value, keyLabel) => {
    setter1(value);
    setter2(value?.map((data) => data[keyLabel]));
  };

  const handleDeselectAll = (setter1, setter2) => {
    setter1([]);
    setter2([]);
  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <Stack direction="row" className="data-analysis-filter-part">
      <AnalysisModalFilterDropdown
        dropdownWidth={350}
        dropdownData={departments}
        selectedOption={selectedDepartments}
        label={chooseDepartmentText}
        handleChange={handleDepartmentChange}
        handleClear={(e) => {
          e.stopPropagation();
          handleClear(
            setSelectedDepartments,
            setSelectedDepartmentIds,
            departments,
            idText,
          );
        }}
        handleDeselectAll={(e) => {
          handleDeselectAll(setSelectedDepartments, setSelectedDepartmentIds);
        }}
        keyLabel={departmentNameText}
        keyId={idText}
        count={""}
      />

      <AnalysisModalSearchTextField
        searchData={searchData}
        handleSearch={handleSearch}
        setSearchData={setSearchData}
        label={searchStaffText}
      />
    </Stack>
  );
};
