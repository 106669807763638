import React from "react";
import { Box, Typography } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import CircularProgress from "@mui/material/CircularProgress";
import { SubjectBalancingTable } from "./SubjectBalancingTable";

// =======================================|| SUBJECT BALANCING RHS CONTENT ||======================================= //

export const SubjectBalancingRhsContent = ({
  fetchData,
  filteredSubjectBalancingData,
}) => {
  const {
    labelText: { noSubjectText },
  } = uiTexts;

  const renderContent = () => {
    switch (true) {
      case fetchData:
        return (
          <Box className="data-analysis-rhs-loader">
            <CircularProgress />
          </Box>
        );

      case filteredSubjectBalancingData?.length > 0:
        return (
          <SubjectBalancingTable
            filteredSubjectBalancingData={filteredSubjectBalancingData}
          />
        );

      default:
        return (
          <Typography className="data-analysis-rhs-data-not-found">
            {noSubjectText}
          </Typography>
        );
    }
  };

  return <>{renderContent()}</>;
};
