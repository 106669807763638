import React from "react";
import { TableBody, TableRow } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import {
  formatTableItem,
  sortDataByLocaleCompare,
} from "../../../../../../../Utils/commonMethods";
import { AnalysisRhsTableItem } from "../../../../Analysis/AnalysisCommonComponents/AnalysisRhsTableItem";
import "../../dataanalysis.css";

// =======================================|| INFRASTRUCTURE UTILIZATION TABLE ITEM ||======================================== //

export const InfrastructureUtilizationTableItem = ({
  filteredInfrastructureData,
}) => {
  const {
    buttonText: { yesText, noText },
    labelText: { buildingNameText },
  } = uiTexts;

  const sortedFilteredData = sortDataByLocaleCompare(
    filteredInfrastructureData?.details,
    buildingNameText,
  );

  return (
    <TableBody>
      {sortedFilteredData?.map((detail, index) => (
        <TableRow
          key={index}
          className={
            !detail.canAssignAllSubjects &&
            "infrastructure-utilization-table-row-highlight"
          }
        >
          <AnalysisRhsTableItem
            item={index + 1}
            highlight={!detail.canAssignAllSubjects}
          />

          <AnalysisRhsTableItem
            item={detail.buildingName}
            highlight={!detail.canAssignAllSubjects}
          />

          <AnalysisRhsTableItem
            item={formatTableItem(detail.roomName, detail.roomCapacity)}
            highlight={!detail.canAssignAllSubjects}
          />

          <AnalysisRhsTableItem
            item={detail.multipleSubjectsAllowedAtOneTime ? yesText : noText}
            highlight={!detail.canAssignAllSubjects}
          />

          <AnalysisRhsTableItem
            item={detail.roomType}
            highlight={!detail.canAssignAllSubjects}
          />

          <AnalysisRhsTableItem
            item={detail.availableSlots}
            highlight={!detail.canAssignAllSubjects}
          />

          <AnalysisRhsTableItem
            item={detail.possibleSubjects}
            highlight={!detail.canAssignAllSubjects}
          />

          <AnalysisRhsTableItem
            item={detail.totalSubjectHours}
            highlight={!detail.canAssignAllSubjects}
          />

          <AnalysisRhsTableItem
            item={detail.canAssignAllSubjects ? yesText : noText}
            highlight={!detail.canAssignAllSubjects}
          />
        </TableRow>
      ))}
    </TableBody>
  );
};
