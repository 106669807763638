import React from "react";
import { TableBody, TableRow } from "@mui/material";
import {
  formatTableItem,
  sortDataByLocaleCompare,
} from "../../../../../../../Utils/commonMethods";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { AnalysisRhsTableItem } from "../../../../Analysis/AnalysisCommonComponents/AnalysisRhsTableItem";
import "../../dataanalysis.css";

// =======================================|| STAFF WORKLOAD TABLE ITEM ||======================================== //

export const StaffWorkLoadTableItem = ({ filteredStaffWorkLoadData }) => {
  const {
    labelText: { departmentNameText },
  } = uiTexts;

  const sortedFilteredData = sortDataByLocaleCompare(
    filteredStaffWorkLoadData?.staffDetails,
    departmentNameText,
  );

  return (
    <TableBody>
      {sortedFilteredData?.map((detail, index) => (
        <TableRow
          key={index}
          className={
            detail.staffTeachingHoursMoreThanIdealHours &&
            "infrastructure-utilization-table-row-highlight"
          }
        >
          <AnalysisRhsTableItem
            item={index + 1}
            highlight={detail.staffTeachingHoursMoreThanIdealHours}
          />

          <AnalysisRhsTableItem
            item={detail.departmentName}
            highlight={detail.staffTeachingHoursMoreThanIdealHours}
          />

          <AnalysisRhsTableItem
            item={formatTableItem(detail.staffName, detail.staffRefName)}
            highlight={detail.staffTeachingHoursMoreThanIdealHours}
          />

          <AnalysisRhsTableItem
            item={detail.totalTeachingHours}
            highlight={detail.staffTeachingHoursMoreThanIdealHours}
          />

          {detail?.courseTypeHours?.map((course, courseIndex) => (
            <AnalysisRhsTableItem
              key={courseIndex}
              item={course.teachingHours}
              highlight={detail.staffTeachingHoursMoreThanIdealHours}
            />
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};
