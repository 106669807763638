import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { uiTexts } from "../../Utils/uiTexts";
import { useDispatch } from "react-redux";
import { SemesterLoader } from "../../Store/Reducers/login_menu";
import "./SemesterSelectWarning.css";

// ===================================|| SEMESTER SELECT WARNING ||=================================== //

export const SemesterSelectWarning = () => {
  const {
    semesterSelectWarningText: { pleaseSelectASemesterText },
  } = uiTexts;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigateToHome = () => {
    dispatch(SemesterLoader(false));
    navigate("/home");
  };

  return (
    <div className="semester-select-warning-container">
      <Typography className="semester-select-warning-text">
        <a
          href="/home"
          onClick={handleNavigateToHome}
          className="semester-select-warning-text-style"
        >
          {pleaseSelectASemesterText}
        </a>
      </Typography>
    </div>
  );
};
