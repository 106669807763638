import React from "react";
import { Grid, Paper } from "@mui/material";
import Infrastructure from "./Infrastructure";
import Loader from "../../Loader/Loader";
import AlgorithmWarnings from "../Timetable/Warnings/AlgorithmWarnings";
import Department from "./Department/Department";
import { useSelector } from "react-redux";
import { SemesterSelectWarning } from "../../SemesterSelectWarning/SemesterSelectWarning";
import "../MainPage.css";

// ==============================|| INFRASTRUCTURE MAIN LAYOUT ||============================== //

const MainLayout = ({
  handleBuildingClick,
  handleRemoveBuilding,
  handleRoomClick,
  handleRemoveRoom,
  handleDepartmentClick,
  handleRemoveDepartment,
}) => {
  const menu = useSelector((state) => state.infrastructure_menu);
  const isBuildingStatusCode = menu.isBuildingStatusCode;
  const isDepartmentStatusCode = menu.isDepartmentStatusCode;

  const course_menu = useSelector((state) => state.course_menu);
  const isCourseStatusCode = course_menu.isCourseStatusCode;

  const class_menu = useSelector((state) => state.class_menu);
  const isClassStatusCode = class_menu.isClassStatusCode;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const isCombinedClassStatusCode =
    combined_class_menu.isCombinedClassStatusCode;

  const staff_menu = useSelector((state) => state.staff_menu);
  const isStaffStatusCode = staff_menu.isStaffStatusCode;

  const fixedslot_menu = useSelector((state) => state.fixedslot_menu);
  const isFixedSlotStatusCode = fixedslot_menu.isFixedSlotStatusCode;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  return (
    <>
      {(isBuildingStatusCode === false ||
        isDepartmentStatusCode === false ||
        isCourseStatusCode === false ||
        isClassStatusCode === false ||
        isCombinedClassStatusCode === false ||
        isStaffStatusCode === false ||
        isFixedSlotStatusCode === false ||
        algorithmStatus === "") &&
      sessionStorage.getItem("branchName") !== null ? (
        <Loader />
      ) : (
        <>
          {sessionStorage.getItem("branchName") !== null ? (
            <>
              <div className="display-warnings">
                <AlgorithmWarnings />
              </div>

              <Paper className="infrastructure-paper-padding" sx={{ mt: 0 }}>
                <Grid container rowSpacing={1}>
                  <Grid item xs={8.5}>
                    {/* Buildings and Rooms */}
                    <Infrastructure
                      handleBuildingClick={handleBuildingClick}
                      handleRemoveBuilding={handleRemoveBuilding}
                      handleRoomClick={handleRoomClick}
                      handleRemoveRoom={handleRemoveRoom}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={2.5}
                    className="infrastructure-department-area-margin"
                  >
                    {/* Departments */}
                    <Department
                      handleDepartmentClick={handleDepartmentClick}
                      handleRemoveDepartment={handleRemoveDepartment}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </>
          ) : (
            <SemesterSelectWarning />
          )}
        </>
      )}
    </>
  );
};

export default MainLayout;
