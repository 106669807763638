import React from "react";
import { Box, Typography } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import CircularProgress from "@mui/material/CircularProgress";
import { StaffWorkLoadBalancingRhsInfo } from "./StaffWorkLoadBalancingRhsInfo";
import { StaffWorkLoadBalancingTable } from "./StaffWorkLoadBalancingTable";

// =======================================|| STAFF WORKLOAD BALANCING RHS CONTENT ||======================================= //

export const StaffWorkLoadBalancingRhsContent = ({
  fetchData,
  filteredStaffWorkLoadBalancingData,
}) => {
  const {
    labelText: { noStaffText },
  } = uiTexts;

  const showRhsContent =
    filteredStaffWorkLoadBalancingData?.staffWorkLoadBalanceDTOS?.length > 0;

  const renderContent = () => {
    switch (true) {
      case fetchData:
        return (
          <Box className="data-analysis-rhs-loader">
            <CircularProgress />
          </Box>
        );

      case showRhsContent:
        return (
          <>
            <StaffWorkLoadBalancingRhsInfo
              filteredStaffWorkLoadBalancingData={
                filteredStaffWorkLoadBalancingData
              }
            />

            <StaffWorkLoadBalancingTable
              filteredStaffWorkLoadBalancingData={
                filteredStaffWorkLoadBalancingData
              }
            />
          </>
        );

      default:
        return (
          <Typography className="data-analysis-rhs-data-not-found">
            {noStaffText}
          </Typography>
        );
    }
  };

  return <>{renderContent()}</>;
};
