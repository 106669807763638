import React from "react";
import { TableBody, TableRow } from "@mui/material";
import {
  roundedPercentage,
  sortDataByLocaleCompare,
} from "../../../../../../../Utils/commonMethods";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { AnalysisRhsTableItem } from "../../../AnalysisCommonComponents/AnalysisRhsTableItem";
import "../../../DataAnalysis/dataanalysis.css";

// =======================================|| STAFF WORKLOAD BALANCING TABLE ITEM ||======================================== //

export const StaffWorkLoadBalancingTableItem = ({
  filteredStaffWorkLoadBalancingData,
}) => {
  const {
    labelText: { departmentNameText },
  } = uiTexts;

  const sortedFilteredData = sortDataByLocaleCompare(
    filteredStaffWorkLoadBalancingData?.staffWorkLoadBalanceDTOS,
    departmentNameText,
  );

  return (
    <TableBody>
      {sortedFilteredData?.map((detail, index) => (
        <TableRow key={index}>
          <AnalysisRhsTableItem item={index + 1} />

          <AnalysisRhsTableItem item={detail.departmentName} />

          <AnalysisRhsTableItem item={detail.staffName} />

          <AnalysisRhsTableItem item={detail.noOfTeachingHoursWithoutBreak} />

          <AnalysisRhsTableItem
            item={roundedPercentage(detail.workLoadBalancedPercentageMatched)}
          />
        </TableRow>
      ))}
    </TableBody>
  );
};
