import React from "react";
import {
  Alert,
  AlertTitle,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import "../../Pages/Dashboard/Timetable/Timetable.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { uiTexts } from "../../Utils/uiTexts";

// ==================================|| ALERT COMPONENT ||======================================== //

export const AlertComponent = ({
  severity,
  alertCss,
  title,
  viewClick,
  downloadClick,
}) => {
  const {
    buttonText: { viewText, downloadText },
  } = uiTexts;

  return (
    <Alert severity={severity} className={alertCss}>
      <Stack direction="row">
        <AlertTitle className="import-warning__alert-title">
          <b>{title}</b>
        </AlertTitle>

        <Stack direction="row" className="view_icon">
          <IconButton className="alert-icon-button-border" onClick={viewClick}>
            <VisibilityIcon className="visibilityIcon-color" />

            <Typography className="view_title">{viewText}</Typography>
          </IconButton>
        </Stack>

        <Stack direction="row" className="download_icon">
          <IconButton
            className="alert-icon-button-border"
            onClick={downloadClick}
          >
            <VerticalAlignBottomIcon className="visibilityIcon-color" />

            <Typography className="download_title">{downloadText}</Typography>
          </IconButton>
        </Stack>
      </Stack>
    </Alert>
  );
};
