import React from "react";
import {
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Box,
  CircularProgress,
} from "@mui/material";
import "../../resultanalysis.css";
import { useSelector } from "react-redux";

// ==================================|| OVERALL PERCENTAGE TABLE ITEM ||===================================== //

export const OverallPercentageTableItem = () => {
  const overAllPercentageData = useSelector(
    (state) => state.resultanalysis_menu.overAllPercentageData,
  );

  const percentageDataArray = overAllPercentageData
    ? Object.entries(overAllPercentageData)?.map(([key, value]) => ({
        key,
        label: key
          .replace(/([A-Z])/g, " $1") // Add space before uppercase letters
          .toLowerCase() // Convert entire string to lowercase
          .replace(/^./, (str) => str.toUpperCase()), // Capitalize first letter
        value,
      }))
    : [];

  const getProgressColor = (value) => {
    if (value >= 75) {
      return "#009A2B";
    } else if (value >= 50) {
      return "#03008D";
    } else {
      return "#FF0000";
    }
  };

  const CircularProgressWithLabel = ({ value }) => {
    return (
      <Box className="overall-percentage-bar-box">
        <CircularProgress
          variant="determinate"
          value={value}
          thickness={5}
          className="overall-percentage-bar"
          sx={{
            color: getProgressColor(value),
          }}
        />

        <Box className="overall-percentage-bar-typo-box">
          <Typography
            variant="caption"
            component="div"
            className="overall-percentage-bar-label"
          >
            {`${Math.round(value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  };

  const RenderTableRow = ({ label, value }) => {
    return (
      <TableRow>
        <TableCell className="overall-percentage-table-cell overall-percentage-table-body">
          <Typography className="infrastructure-utilization-table-cell infrastructure-utilization-table-cell-count">
            {label}
          </Typography>
        </TableCell>

        <TableCell className="overall-percentage-table-cell overall-percentage-table-body">
          <CircularProgressWithLabel value={value} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableBody className="overall-percentage-table-body">
      {percentageDataArray?.map(({ key, label, value }) => (
        <RenderTableRow key={key} label={label} value={value} />
      ))}
    </TableBody>
  );
};
