import React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { uiTexts } from "../../../../Utils/uiTexts";
import { HomePageTable } from "./HomePageTable/HomePageTable";
import "../HomePage.css";

// ========================================|| HOME PAGE CONTENT ||========================================= //

export const HomePageContent = ({
  setAddSemesterModal,
  setSelectedSemester,
}) => {
  const {
    homePageText: { newSemesterText },
  } = uiTexts;

  return (
    <div className="semester-table semester-table-box">
      <Button
        className="add-new-semester-button"
        onClick={() => setAddSemesterModal(true)}
      >
        <AddIcon sx={{ mr: 1 }} />
        {newSemesterText}
      </Button>

      <HomePageTable setSelectedSemester={setSelectedSemester} />
    </div>
  );
};
