import React from "react";
import { TableBody, TableCell, TableRow, Radio } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AlgorithmService from "../../../../../Services/AlgorithmService";
import {
  setBranchName,
  setNewUserSession,
} from "../../../../../Utils/commonMethods";
import {
  AlgorithmStatus,
  AlgorithmFailedError,
} from "../../../../../Store/Reducers/menu";
import { uiTexts } from "../../../../../Utils/uiTexts";
import "../../HomePage.css";

// ========================================|| HOME PAGE TABLE ITEM ||========================================= //

export const HomePageTableItem = ({ setSelectedSemester }) => {
  const {
    algorithmStatusText: { failedText },
  } = uiTexts;

  const dispatch = useDispatch();

  const { semesterDetails, selectedSemesterId } = useSelector(
    (state) => state.login_menu,
  );

  const retrieveAlgorithmStatus = async () => {
    await AlgorithmService.getAlgorithmStatus().then(({ data }) => {
      dispatch(AlgorithmStatus(data?.algorithmStatus));

      if (data?.algorithmStatus === failedText) {
        dispatch(AlgorithmFailedError(data?.moreInfo));
      }
    });
  };

  const handleSelectSemester = (rowId) => {
    const selectedValue = rowId;
    setSelectedSemester(selectedValue);

    const selectedSemesterDetails = semesterDetails?.find(
      (semester) => semester.id === selectedValue,
    );

    if (selectedSemesterDetails) {
      setBranchName(selectedSemesterDetails.tenantName);
      setNewUserSession(
        selectedSemesterDetails.id,
        selectedSemesterDetails.academicYear,
        selectedSemesterDetails.semesterType,
        selectedSemesterDetails.tenantName,
        selectedSemesterDetails.planCreatedDate,
        selectedSemesterDetails.planUpdatedDate,
        selectedSemesterDetails.planCompletedDate,
        selectedSemesterDetails.planStatus,
      );

      retrieveAlgorithmStatus();
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date?.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const RenderItem = ({ item, isLast }) => {
    return (
      <TableCell
        className="semester-content-text semester-table-cell-item-text"
        style={isLast && { borderRight: "none" }}
      >
        {item}
      </TableCell>
    );
  };

  return (
    <TableBody>
      {semesterDetails?.map((semester, index) => {
        const isSelected = semester.id === selectedSemesterId;

        return (
          <TableRow
            tabIndex={-1}
            key={semester.id}
            selected={isSelected}
            onClick={() => handleSelectSemester(semester.id)}
            className="show-cursor"
          >
            <TableCell
              padding="radio"
              className="semester-content-text semester-table-cell-item-text"
            >
              <Radio
                checked={isSelected}
                color="primary"
                sx={{
                  "& .MuiSvgIcon-root": {
                    width: 18,
                    height: 18,
                  },
                }}
              />
            </TableCell>

            <RenderItem item={index + 1} />

            <RenderItem item={semester.academicYear} />

            <RenderItem item={semester.semesterType} />

            <RenderItem item={formatDate(semester.planCreatedDate)} />

            <RenderItem
              item={
                semester.planUpdatedDate
                  ? formatDate(semester.planUpdatedDate)
                  : "-"
              }
            />

            <RenderItem
              item={
                semester.planCompletedDate
                  ? formatDate(semester.planCompletedDate)
                  : "-"
              }
            />

            <RenderItem item={semester.planStatus} isLast={true} />
          </TableRow>
        );
      })}
    </TableBody>
  );
};
