import React from "react";
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useSelector } from "react-redux";
import { uiTexts } from "../../../../Utils/uiTexts";
import "../HomePage.css";

// ====================================|| ADD SEMESTER MODAL RADIO BUTTONS ||========================================= //

export const AddSemesterModalRadioButtons = ({ handleSemesterTypeChange }) => {
  const {
    homePageText: { semesterTypeText },
  } = uiTexts;

  const semesterType = useSelector((state) => state.login_menu.semesterType);

  return (
    <>
      <Typography sx={{ mt: 2 }} className="add-semester-labels">
        {semesterTypeText}
      </Typography>

      <FormControl className="input-style">
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          className="semester-modal-radio-group-style"
        >
          {semesterType?.map((type, index) => (
            <FormControlLabel
              value={type}
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": {
                      width: 19,
                      height: 19,
                    },
                  }}
                />
              }
              label={type}
              onChange={handleSemesterTypeChange}
              className="semester-type-style"
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};
