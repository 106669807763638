import React from "react";
import { Typography, FormControl, Select, MenuItem } from "@mui/material";
import { commonMenuProps } from "../../../../Utils/commonMethods";
import "../HomePage.css";

// ======================================|| ADD SEMESTER MODAL DROPDOWN ||========================================= //

export const AddSemesterModalDropdown = ({
  xsValue,
  label,
  value,
  handleChange,
  placeholder,
  dropdownData,
  keyId,
  keyLabel,
}) => {
  return (
    <>
      <Typography className="add-semester-labels" sx={xsValue}>
        {label}
      </Typography>

      <FormControl className="input-style">
        <Select
          value={value}
          onChange={handleChange}
          size="small"
          style={{ color: value === 0 ? "#aaacae" : "#000000" }}
          displayEmpty
          MenuProps={commonMenuProps}
        >
          <MenuItem value={0} disabled>
            {placeholder}
          </MenuItem>

          {dropdownData?.map((data) => (
            <MenuItem key={data[keyId]} value={data[keyId]}>
              {data[keyLabel]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
