import React from "react";
import { Box, Typography } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import CircularProgress from "@mui/material/CircularProgress";
import { StaffWorkLoadRhsInfo } from "./StaffWorkLoadRhsInfo";
import { StaffWorkLoadTable } from "./StaffWorkLoadTable";

// ==============================================|| STAFF WORKLOAD RHS CONTENT ||============================================ //

export const StaffWorkloadRhsContent = ({
  fetchData,
  filteredStaffWorkLoadData,
}) => {
  const {
    labelText: { noStaffText },
  } = uiTexts;

  const renderContent = () => {
    switch (true) {
      case fetchData:
        return (
          <Box className="data-analysis-rhs-loader">
            <CircularProgress />
          </Box>
        );

      case filteredStaffWorkLoadData?.staffDetails?.length > 0:
        return (
          <>
            <StaffWorkLoadRhsInfo
              filteredStaffWorkLoadData={filteredStaffWorkLoadData}
            />

            <StaffWorkLoadTable
              filteredStaffWorkLoadData={filteredStaffWorkLoadData}
            />
          </>
        );

      default:
        return (
          <Typography className="data-analysis-rhs-data-not-found">
            {noStaffText}
          </Typography>
        );
    }
  };

  return renderContent();
};
