import React from "react";
import { TableHead, TableRow, TableCell, Typography } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";

// ==================================|| INFRASTRUCTURE UTILIZATION TABLE HEADER ||===================================== //

export const InfrastructureUtilizationTableHeader = ({
  filteredInfrastructureData,
}) => {
  const {
    labelText: {
      buildingText,
      roomTypeText,
      sNoText,
      isMultipleSubjectsAllowedText,
      roomCapacityText,
      availableSlotsText,
      possibleSubjectsText,
      totalSubjectHoursText,
      canAssignAllSubjectsText,
    },
  } = uiTexts;

  const { numberOfBuildings, numberOfRooms, numberOfRoomType } =
    filteredInfrastructureData || {};

  const infrastructureUtilizationHeader = [
    sNoText,
    buildingText,
    roomCapacityText,
    isMultipleSubjectsAllowedText,
    roomTypeText,
    availableSlotsText,
    possibleSubjectsText,
    totalSubjectHoursText,
    canAssignAllSubjectsText,
  ];

  const getCount = (text) => {
    switch (text) {
      case buildingText:
        return `(${numberOfBuildings})`;
      case roomCapacityText:
        return `(${numberOfRooms})`;
      case roomTypeText:
        return `(${numberOfRoomType})`;
      default:
        return "";
    }
  };

  return (
    <TableHead>
      <TableRow>
        {infrastructureUtilizationHeader?.map((text, index) => (
          <TableCell
            className="infrastructure-utilization-table-cell"
            key={index}
          >
            {text}

            <Typography className="infrastructure-utilization-table-cell infrastructure-utilization-table-cell-count">
              {getCount(text)}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
