import { uiTexts } from "../../../../../../Utils/uiTexts";
import "jspdf-autotable";
import CampusPlannerLogo from "../../../../../../Assets/Images/CampusPlannerLogo.png";
import VVVLogo from "../../../../../../Assets/Images/vvvclogo.png";
import {
  getUniqueCount,
  roundedPercentage,
  sortDataByLocaleCompare,
} from "../../../../../../Utils/commonMethods";

// ====================================== || SUBJECT BALANCING REPORT || ======================================= //

export const subjectBalancingReport = (doc, subjectBalancingData) => {
  const {
    labelText: {
      sNoText,
      departmentText,
      classText,
      subjectText,
      typeCombinedGroupIndividualText,
      subjectHoursText,
      daysAllottedText,
      subjectBalancePercentageText,
      departmentNameText,
      classNameText,
      subjectLowerCaseText,
    },
    pdfReportText: { vvvCollegeText, subjectBalancingReportText },
  } = uiTexts;

  const sortedSubjectBalancingData = sortDataByLocaleCompare(
    subjectBalancingData,
    departmentNameText,
  );

  doc.addPage();
  const newStartY = 20;
  const width = doc.internal.pageSize.getWidth();

  doc.setFontSize(18);
  doc.addImage(VVVLogo, "PNG", 10, newStartY - 15, 15, 15);
  doc.addImage(CampusPlannerLogo, "JPG", 320, newStartY - 15, 25, 15);
  doc.text(vvvCollegeText, width / 2, newStartY - 5, { align: "center" });
  doc.setFontSize(13);
  doc.text(subjectBalancingReportText, width / 2, newStartY + 5, {
    align: "center",
  });

  doc.setFontSize(12);
  const uniqueDepartmentCount = getUniqueCount(
    subjectBalancingData,
    departmentNameText,
  );
  const uniqueClassCount = getUniqueCount(subjectBalancingData, classNameText);
  const uniqueSubjectCount = getUniqueCount(
    subjectBalancingData,
    subjectLowerCaseText,
  );

  const subjectBalancingHeaders = [
    sNoText,
    `${departmentText} (${uniqueDepartmentCount})`,
    `${classText} (${uniqueClassCount})`,
    `${subjectText} (${uniqueSubjectCount})`,
    typeCombinedGroupIndividualText,
    subjectHoursText,
    daysAllottedText,
    subjectBalancePercentageText,
  ];

  const subjectBalancingRows = sortedSubjectBalancingData?.map(
    (detail, index) => [
      index + 1,
      detail.departmentName,
      detail.className,
      detail.subject,
      detail.subjectType,
      detail.numberOfHours,
      detail.numberOfDaysAllotted,
      roundedPercentage(detail.subjectBalancePercentage),
    ],
  );

  doc.autoTable({
    head: [subjectBalancingHeaders],
    body: subjectBalancingRows,
    startY: newStartY + 25,
    theme: "grid",
    halign: "center",
    valign: "middle",
    styles: { fontSize: 10, minCellHeight: 10 },
  });
};
