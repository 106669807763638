import React from "react";
import { Stack, Typography } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { AnalysisModalFilterDropdown } from "../../../AnalysisCommonComponents/AnalysisModalFilterDropdown";
import { AnalysisModalSearchTextField } from "../../../AnalysisCommonComponents/AnalysisModalSearchTextField";

// =======================================|| STAFF WORKLOAD BALANCING FILTERS ||======================================= //

export const StaffWorkLoadBalancingFilters = ({
  departments,
  selectedDepartments,
  setSelectedDepartments,
  setSelectedDepartmentIds,
  searchData,
  setSearchData,
  filteredStaffWorkLoadBalancingData,
}) => {
  const {
    labelText: {
      chooseDepartmentText,
      departmentNameText,
      searchStaffText,
      totalHoursWithOutBreaksText,
      idText,
    },
    symbolText: { colonText },
  } = uiTexts;

  const staffWorkLoadBalanceDTOSList =
    filteredStaffWorkLoadBalancingData?.staffWorkLoadBalanceDTOS;
  const maximumTeachingBreakHours =
    staffWorkLoadBalanceDTOSList?.length > 0
      ? Math.max(
          ...staffWorkLoadBalanceDTOSList?.map(
            (staff) => staff.maximumNumberOfHoursTeachingBreak,
          ),
        )
      : 0;

  const handleDepartmentChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDepartments(value);
    setSelectedDepartmentIds(
      value?.map((department) => department.departmentId),
    );
  };

  const handleClear = (setter1, setter2, value, keyLabel) => {
    setter1(value);
    setter2(value?.map((data) => data[keyLabel]));
  };

  const handleDeselectAll = (setter1, setter2) => {
    setter1([]);
    setter2([]);
  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <Stack direction="row" className="data-analysis-filter-part">
      <AnalysisModalFilterDropdown
        dropdownWidth={350}
        dropdownData={departments}
        selectedOption={selectedDepartments}
        label={chooseDepartmentText}
        handleChange={handleDepartmentChange}
        handleClear={(e) => {
          e.stopPropagation();
          handleClear(
            setSelectedDepartments,
            setSelectedDepartmentIds,
            departments,
            idText,
          );
        }}
        handleDeselectAll={(e) => {
          handleDeselectAll(setSelectedDepartments, setSelectedDepartmentIds);
        }}
        keyLabel={departmentNameText}
        keyId={idText}
        count={""}
      />

      <AnalysisModalSearchTextField
        searchData={searchData}
        handleSearch={handleSearch}
        setSearchData={setSearchData}
        label={searchStaffText}
      />

      <Stack direction="row" spacing={0.5} className="ideal-teaching-hours">
        <WarningAmberIcon className="ideal-teaching-hours-icon" />
        <Typography>
          {totalHoursWithOutBreaksText} {colonText} {maximumTeachingBreakHours}
        </Typography>
      </Stack>
    </Stack>
  );
};
