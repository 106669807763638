import { uiTexts } from "../../../../../../Utils/uiTexts";
import "jspdf-autotable";
import CampusPlannerLogo from "../../../../../../Assets/Images/CampusPlannerLogo.png";
import VVVLogo from "../../../../../../Assets/Images/vvvclogo.png";
import { sortDataByLocaleCompare } from "../../../../../../Utils/commonMethods";

// ========================== STAFF WORKLOAD REPORT ============================= //

export const staffWorkloadReport = (doc, staffWorkLoad) => {
  const {
    labelText: {
      sNoText,
      departmentText,
      staffReferenceText,
      totalHoursText,
      hoursText,
      staffExceedingIdealTeachingHours,
      staffBelowIdealTeachingHours,
      departmentNameText,
    },
    symbolText: { colonText },
    pdfReportText: { vvvCollegeText, staffWorkloadReportText },
  } = uiTexts;

  const {
    numberOfStaffExceedingIdealHours,
    numberOfStaffNotExceedingIdealHours,
  } = staffWorkLoad || {};

  const { courseTypeHours, departmentCount, staffCount, totalHours } =
    staffWorkLoad?.staffWorkLoadOverAllDTO || {};

  const sortedStaffWorkLoadData = sortDataByLocaleCompare(
    staffWorkLoad?.staffDetails,
    departmentNameText,
  );

  doc.addPage();
  const newStartY = 20;
  const width = doc.internal.pageSize.getWidth();

  doc.setFontSize(18);
  doc.addImage(VVVLogo, "PNG", 10, newStartY - 15, 15, 15);
  doc.addImage(CampusPlannerLogo, "JPG", 320, newStartY - 15, 25, 15);
  doc.text(vvvCollegeText, width / 2, newStartY - 5, { align: "center" });
  doc.setFontSize(13);
  doc.text(staffWorkloadReportText, width / 2, newStartY + 5, {
    align: "center",
  });

  doc.setFontSize(12);
  doc.text(
    staffExceedingIdealTeachingHours +
      colonText +
      numberOfStaffExceedingIdealHours,
    14,
    newStartY + 15,
    { align: "left" },
  );
  doc.text(
    staffBelowIdealTeachingHours +
      colonText +
      numberOfStaffNotExceedingIdealHours,
    width - 14,
    newStartY + 15,
    { align: "right" },
  );

  // Dynamic course type headers
  const courseTypeHeaders =
    courseTypeHours?.map(
      (course) =>
        `${course.courseTypeName} ${hoursText} (${course.teachingHours})`,
    ) || [];

  const staffWorkLoadHeader = [
    sNoText,
    `${departmentText} (${departmentCount})`,
    `${staffReferenceText} (${staffCount})`,
    `${totalHoursText} (${totalHours})`,
    ...courseTypeHeaders,
  ];

  const staffWorkLoadRows = sortedStaffWorkLoadData?.map((detail, index) => [
    index + 1,
    detail.departmentName,
    `${detail.staffName} (${detail.staffRefName})`,
    detail.totalTeachingHours,
    ...(detail.courseTypeHours?.map((course) => course.teachingHours) || []), // Spread dynamic course type hours into separate cells
  ]);

  doc.autoTable({
    head: [staffWorkLoadHeader],
    body: staffWorkLoadRows,
    startY: newStartY + 25,
    theme: "grid",
    halign: "center",
    valign: "middle",
    styles: { fontSize: 10, minCellHeight: 10 },
  });
};
