import { uiTexts } from "../../../../../../Utils/uiTexts";
import "jspdf-autotable";
import CampusPlannerLogo from "../../../../../../Assets/Images/CampusPlannerLogo.png";
import VVVLogo from "../../../../../../Assets/Images/vvvclogo.png";
import {
  getUniqueCount,
  roundedPercentage,
  sortDataByLocaleCompare,
} from "../../../../../../Utils/commonMethods";

// ====================================== || STAFF WORKLOAD BALANCING REPORT || ======================================= //

export const staffWorkLoadBalancingReport = (
  doc,
  staffWorkLoadBalancingData,
) => {
  const {
    labelText: {
      sNoText,
      departmentText,
      staffText,
      teachingHoursWithoutABreakExcludingContinuousLabHoursText,
      workLoadBalanceMatchPercentageText,
      staffAssignedDuringTheFirstHourAcrossAllDaysText,
      staffAssignedDuringTheLastHourAcrossAllDaysText,
      noOfStaffWithNoAssignedHoursOnAnyDayText,
      departmentNameText,
      staffNameText,
      totalHoursWithOutBreaksText,
    },
    symbolText: { colonText },
    pdfReportText: { vvvCollegeText, staffWorkLoadBalancingReportText },
  } = uiTexts;

  const {
    firstHourAllAssignedStaffCount,
    lastHourAllAssignedStaffCount,
    noHoursAssignedOnAnyDayStaffCount,
  } = staffWorkLoadBalancingData?.hoursBasedDTO || {};

  const staffWorkLoadBalanceDTOSList =
    staffWorkLoadBalancingData?.staffWorkLoadBalanceDTOS || [];

  const uniqueDepartmentCount = getUniqueCount(
    staffWorkLoadBalanceDTOSList,
    departmentNameText,
  );
  const uniqueStaffCount = getUniqueCount(
    staffWorkLoadBalanceDTOSList,
    staffNameText,
  );

  const maximumTeachingBreakHours =
    staffWorkLoadBalanceDTOSList?.length > 0
      ? Math.max(
          ...staffWorkLoadBalanceDTOSList?.map(
            (staff) => staff.maximumNumberOfHoursTeachingBreak,
          ),
        )
      : 0;

  const sortedStaffWorkLoadBalancingData = sortDataByLocaleCompare(
    staffWorkLoadBalanceDTOSList,
    departmentNameText,
  );

  doc.addPage();
  const newStartY = 20;
  const width = doc.internal.pageSize.getWidth();

  doc.setFontSize(18);
  doc.addImage(VVVLogo, "PNG", 10, newStartY - 15, 15, 15);
  doc.addImage(CampusPlannerLogo, "JPG", 320, newStartY - 15, 25, 15);
  doc.text(vvvCollegeText, width / 2, newStartY - 5, { align: "center" });
  doc.setFontSize(13);
  doc.text(staffWorkLoadBalancingReportText, width / 2, newStartY + 5, {
    align: "center",
  });

  doc.setFontSize(12);
  const firstTextY = newStartY + 15;
  const secondTextY = firstTextY;
  const thirdTextY = firstTextY + 8;
  const fourthTextY = secondTextY + 8;

  doc.text(
    staffAssignedDuringTheFirstHourAcrossAllDaysText +
      colonText +
      firstHourAllAssignedStaffCount,
    14,
    firstTextY,
    { align: "left" },
  );
  doc.text(
    noOfStaffWithNoAssignedHoursOnAnyDayText +
      colonText +
      noHoursAssignedOnAnyDayStaffCount,
    14,
    thirdTextY,
    { align: "left" },
  );
  doc.text(
    staffAssignedDuringTheLastHourAcrossAllDaysText +
      colonText +
      lastHourAllAssignedStaffCount,
    width - 14,
    secondTextY,
    { align: "right" },
  );
  doc.text(
    totalHoursWithOutBreaksText + colonText + maximumTeachingBreakHours,
    width - 14,
    fourthTextY,
    { align: "right" },
  );

  const staffWorkLoadBalancingHeaders = [
    sNoText,
    `${departmentText} (${uniqueDepartmentCount})`,
    `${staffText} (${uniqueStaffCount})`,
    teachingHoursWithoutABreakExcludingContinuousLabHoursText,
    workLoadBalanceMatchPercentageText,
  ];

  const staffWorkLoadBalancingRows = sortedStaffWorkLoadBalancingData?.map(
    (detail, index) => [
      index + 1,
      detail.departmentName,
      detail.staffName,
      detail.noOfTeachingHoursWithoutBreak,
      roundedPercentage(detail.workLoadBalancedPercentageMatched),
    ],
  );

  doc.autoTable({
    head: [staffWorkLoadBalancingHeaders],
    body: staffWorkLoadBalancingRows,
    startY: newStartY + 25,
    theme: "grid",
    halign: "center",
    valign: "middle",
    styles: { fontSize: 10, minCellHeight: 10 },
  });
};
