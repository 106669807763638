import React from "react";
import { TableBody, TableRow, TableCell, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getDay, getTime } from "../../../../../../../Utils/commonMethods";

// ==================================|| GROUP SCHEDULES ACCORDION TABLE ITEM ||===================================== //

export const GroupSchedulesAccordionTableItem = ({ schedule }) => {
  const { dayList, timeList } = useSelector((state) => state.menu);

  let serialNo = 1;

  // Function to render a table row
  const renderTableRow = (courseNames, dayAndTimeDTOS) => (
    <TableRow>
      <TableCell className="infrastructure-utilization-table-cell">
        {courseNames?.map((course, index) => (
          <Typography
            key={index}
            className="infrastructure-utilization-table-cell infrastructure-utilization-table-item group-schedules-table-item-border"
          >
            {serialNo++}. {course}
          </Typography>
        ))}
      </TableCell>

      <TableCell className="infrastructure-utilization-table-cell">
        {dayAndTimeDTOS?.map((slot, index) => (
          <Typography
            key={index}
            className="infrastructure-utilization-table-cell infrastructure-utilization-table-item group-schedules-table-item-border"
          >
            {getDay(slot.dayId, dayList)} - {getTime(slot.timeId, timeList)}
          </Typography>
        ))}
      </TableCell>
    </TableRow>
  );

  return (
    <TableBody>
      {schedule?.matchedCourses?.courseNames?.length > 0 &&
        renderTableRow(
          schedule.matchedCourses.courseNames,
          schedule.matchedCourses.matchedDayAndTimeDTOS,
        )}

      {schedule?.unMatchedCourses?.length > 0 &&
        schedule.unMatchedCourses.map((course, index) =>
          renderTableRow([course.courseName], course.unMatchedDayAndTimeDTOS),
        )}
    </TableBody>
  );
};
