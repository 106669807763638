import { uiTexts } from "../../../../../../Utils/uiTexts";
import jsPDF from "jspdf";
import { infrastructureUtilizationReport } from "./InfrastuctureUtilizationReport";
import { staffWorkloadReport } from "./StaffWorkloadReport";

// ========================================== DATA ANALYSIS REPORT ============================================= //

export const dataAnalysisReport = (infrastructureData, staffWorkLoad) => {
  const {
    pdfReportNameText: { dataAnalysisReportText },
  } = uiTexts;

  const doc = new jsPDF({ orientation: "landscape", format: "legal" });

  infrastructureUtilizationReport(doc, infrastructureData);

  staffWorkloadReport(doc, staffWorkLoad);

  doc.save(dataAnalysisReportText);
};
