import React from "react";
import {
  AccordionDetails,
  Typography,
  TableContainer,
  Table,
  Paper,
  Stack,
} from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { GroupSchedulesAccordionTableHeader } from "./GroupSchedulesAccordionTableHeader";
import { GroupSchedulesAccordionTableItem } from "./GroupSchedulesAccordionTableItem";

// ====================================|| GROUP SCHEDULES ACCORDION CONTENT ||======================================== //

export const GroupSchedulesAccordionContent = ({ schedule }) => {
  const {
    labelText: { subjectHoursText },
    symbolText: { colonText },
  } = uiTexts;

  return (
    <AccordionDetails>
      <Paper elevation={3}>
        <Stack className="group-schedules-accordion-content-info">
          <Typography className="data-analysis-rhs-info-text">
            {subjectHoursText} {colonText}
            <span className="data-analysis-rhs-info-value">
              {schedule.courseHours}
            </span>
          </Typography>
        </Stack>

        <TableContainer className="group-schedules-accordion-content-table">
          <Table stickyHeader>
            <GroupSchedulesAccordionTableHeader />

            <GroupSchedulesAccordionTableItem schedule={schedule} />
          </Table>
        </TableContainer>
      </Paper>
    </AccordionDetails>
  );
};
