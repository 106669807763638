import React from "react";
import { TableHead, TableRow, TableCell, Typography } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { staffPreferenceMatchHeader } from "../../../../../../../Utils/globalUtils";
import { getUniqueCount } from "../../../../../../../Utils/commonMethods";

// ==================================|| STAFF PREFERENCE MATCH TABLE HEADER ||===================================== //

export const StaffPreferenceMatchTableHeader = ({
  filteredStaffPreferenceMatchData,
}) => {
  const {
    labelText: { departmentText, staffText, departmentNameText, staffNameText },
  } = uiTexts;

  const staffPreferenceMatchDTOList =
    filteredStaffPreferenceMatchData?.staffPreferenceMatchDTOList || [];

  const uniqueDepartmentCount = getUniqueCount(
    staffPreferenceMatchDTOList,
    departmentNameText,
  );
  const uniqueStaffCount = getUniqueCount(
    staffPreferenceMatchDTOList,
    staffNameText,
  );

  const getCount = (text) => {
    switch (text) {
      case departmentText:
        return `(${uniqueDepartmentCount})`;
      case staffText:
        return `(${uniqueStaffCount})`;
      default:
        return "";
    }
  };

  return (
    <TableHead>
      <TableRow>
        {staffPreferenceMatchHeader?.map((text, index) => (
          <TableCell
            className="infrastructure-utilization-table-cell"
            key={index}
          >
            <Typography className="infrastructure-utilization-table-cell infrastructure-utilization-table-cell-count">
              {text} {getCount(text)}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
