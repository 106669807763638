import React from "react";
import { Stack } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { AnalysisRhsInfo } from "../../../AnalysisCommonComponents/AnalysisRhsInfo";

// ============================|| SUBJECT CONTINUOUS HOURS PREFERENCE MATCH RHS INFO ||============================= //

export const SubjectContinuousHoursPreferenceMatchRhsInfo = ({
  filteredSubjectContinuousHoursPreferenceMatchData,
}) => {
  const {
    labelText: {
      continuousHourPreferencesText,
      nonContinuousHoursPreferencesText,
    },
  } = uiTexts;

  const efficiencyCount =
    filteredSubjectContinuousHoursPreferenceMatchData?.reduce(
      (acc, item) => {
        item.efficiencyMatched ? acc.trueCount++ : acc.falseCount++;
        return acc;
      },
      { trueCount: 0, falseCount: 0 },
    );

  return (
    <Stack direction="row" className="data-analysis-filter-part">
      <AnalysisRhsInfo
        label={continuousHourPreferencesText}
        value={efficiencyCount?.trueCount}
      />

      <AnalysisRhsInfo
        label={nonContinuousHoursPreferencesText}
        value={efficiencyCount?.falseCount}
      />
    </Stack>
  );
};
