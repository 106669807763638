import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { useDispatch } from "react-redux";
import {
  InfrastructureAnalysisData,
  StaffWorkLoadAnalysisData,
} from "../../../../../../../Store/Reducers/dataanalysis_menu";
import {
  getInfrastructureAnalysis,
  getStaffWorkLoadAnalysis,
} from "../../../../../../../Services/DataAnalysisService";
import "../../../../../../Dashboard/ShortcutModal/shortcutmodal.css";
import "../../dataanalysis.css";

// ====================================== DATA ANALYSIS MODAL LHS LIST ===================================== //

export const DataAnalysisModalLhs = ({
  showSelectedOption,
  setShowSelectedOption,
  setFetchData,
}) => {
  const {
    analysisModalListText: { infrastructureUtilizationText, staffWorkloadText },
  } = uiTexts;

  const dispatch = useDispatch();

  const settingsOptions = [
    { id: 1, tabName: infrastructureUtilizationText },
    { id: 2, tabName: staffWorkloadText },
  ];

  const fetchRhsData = (option) => {
    setFetchData(true);
    setShowSelectedOption(option);

    const infrastructureData = { buildingId: null, roomType: null };
    const staffWorkLoadData = { departmentIds: null };

    const fetchData = (fetchPromise, dispatchAction) => {
      fetchPromise
        .then(({ data }) => {
          dispatch(dispatchAction(data));
        })
        .finally(() => {
          setFetchData(false);
        });
    };

    switch (option) {
      case 1:
        fetchData(
          getInfrastructureAnalysis(infrastructureData),
          InfrastructureAnalysisData,
        );
        break;

      case 2:
        fetchData(
          getStaffWorkLoadAnalysis(staffWorkLoadData),
          StaffWorkLoadAnalysisData,
        );
        break;

      default:
        setFetchData(false);
        break;
    }
  };

  return (
    <Box className="data-analysis-lhs-list">
      <div className="data-analysis-lhs-list-option">
        {settingsOptions?.map((option) => (
          <Button
            key={option.id}
            direction="row"
            className={`data-analysis-lhs-list-button ${
              showSelectedOption === option.id ? "selected" : ""
            }`}
            onClick={() => fetchRhsData(option.id)}
          >
            <Typography className="data-analysis-lhs-list-option-text">
              {option.tabName}
            </Typography>
          </Button>
        ))}
      </div>
    </Box>
  );
};
