import React from "react";
import { Grid, Box } from "@mui/material";
import { OverallPercentage } from "../OverallPercentage/OverallPercentage";
import { StaffPreferenceMatch } from "../StaffPreferenceMatch/StaffPreferenceMatch";
import { StaffWorkLoadBalancing } from "../StaffWorkLoadBalancing/StaffWorkLoadBalancing";
import { SubjectBalancing } from "../SubjectBalancing/SubjectBlancing";
import { SubjectContinuousHoursPreferenceMatch } from "../SubjectContinuousHoursPreferenceMatch/SubjectContinuousHoursPreferenceMatch";
import { GroupSchedules } from "../GroupSchedules/GroupSchedules";
import "../../../../../../Dashboard/ShortcutModal/shortcutmodal.css";
import "../../../DataAnalysis/dataanalysis.css";

// ====================================== RESULT ANALYSIS MODAL RHS ===================================== //

export const ResultAnalysisModalRhs = ({ showSelectedOption }) => {
  const rhsComponent = {
    1: <OverallPercentage />,
    2: <StaffPreferenceMatch />,
    3: <StaffWorkLoadBalancing />,
    4: <SubjectBalancing />,
    5: <SubjectContinuousHoursPreferenceMatch />,
    6: <GroupSchedules />,
  };

  return (
    <Grid className="data-analysis-content">
      <Box width="100%">{rhsComponent[showSelectedOption] || null}</Box>
    </Grid>
  );
};
