import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { resultAnalysisTabOptions } from "../../../../../../../Utils/globalUtils";
import useRetrieveFunctions from "../../../../../../../Components/CustomHooks/useRetrieveFunction";
import "../../../DataAnalysis/dataanalysis.css";
import "../../../../../../Dashboard/ShortcutModal/shortcutmodal.css";

// ====================================== RESULT ANALYSIS MODAL LHS LIST ===================================== //

export const ResultAnalysisModalLhs = ({
  showSelectedOption,
  setShowSelectedOption,
  setFetchData,
}) => {
  const { retrieveOverAllPercentageData } = useRetrieveFunctions();

  const fetchRhsData = async (option) => {
    setFetchData(true);
    setShowSelectedOption(option);

    switch (option) {
      case 1:
        await retrieveOverAllPercentageData();
        setFetchData(false);
        break;

      default:
        setFetchData(false);
        break;
    }
  };

  return (
    <Box className="data-analysis-lhs-list">
      <div className="data-analysis-lhs-list-option">
        {resultAnalysisTabOptions?.map((option) => (
          <Button
            key={option.id}
            direction="row"
            className={`data-analysis-lhs-list-button ${
              showSelectedOption === option.id && "selected"
            }`}
            onClick={() => fetchRhsData(option.id)}
          >
            <Typography className="data-analysis-lhs-list-option-text">
              {option.tabName}
            </Typography>
          </Button>
        ))}
      </div>
    </Box>
  );
};
