import React from "react";
import { TableHead, TableRow, TableCell, Typography } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import {
  insertLineBreakBefore,
  getUniqueCount,
} from "../../../../../../../Utils/commonMethods";
import { subjectBalancingHeader } from "../../../../../../../Utils/globalUtils";

// ==================================|| SUBJECT BALANCING TABLE HEADER ||===================================== //

export const SubjectBalancingTableHeader = ({
  filteredSubjectBalancingData,
}) => {
  const {
    labelText: {
      departmentText,
      classText,
      subjectText,
      typeCombinedGroupIndividualText,
      combinedGroupIndividualText,
      departmentNameText,
      classNameText,
      subjectLowerCaseText,
    },
  } = uiTexts;

  const uniqueDepartmentCount = getUniqueCount(
    filteredSubjectBalancingData,
    departmentNameText,
  );
  const uniqueClassCount = getUniqueCount(
    filteredSubjectBalancingData,
    classNameText,
  );
  const uniqueSubjectCount = getUniqueCount(
    filteredSubjectBalancingData,
    subjectLowerCaseText,
  );

  const getCount = (text) => {
    switch (text) {
      case departmentText:
        return `(${uniqueDepartmentCount})`;
      case classText:
        return `(${uniqueClassCount})`;
      case subjectText:
        return `(${uniqueSubjectCount})`;
      default:
        return "";
    }
  };

  const renderHeaderText = (text) => {
    if (text === typeCombinedGroupIndividualText) {
      return insertLineBreakBefore(
        typeCombinedGroupIndividualText,
        combinedGroupIndividualText,
      );
    }
    return (
      <>
        {text} {getCount(text)}
      </>
    );
  };

  return (
    <TableHead>
      <TableRow>
        {subjectBalancingHeader?.map((text, index) => (
          <TableCell
            className="infrastructure-utilization-table-cell result-analysis-subject-balancing-cell"
            key={index}
          >
            <Typography className="infrastructure-utilization-table-cell infrastructure-utilization-table-cell-count">
              {renderHeaderText(text)}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
