// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = { overAllPercentageData: {} };

// ==============================|| SLICE - RESULT ANALYSIS MENU ||============================== //

const resultanalysis_menu = createSlice({
  name: "resultanalysis_menu",
  initialState,
  reducers: {
    OverAllPercentageData(state, action) {
      state.overAllPercentageData = action.payload;
    },
  },
});

export default resultanalysis_menu.reducer;

export const { OverAllPercentageData } = resultanalysis_menu.actions;
