import React from "react";
import { TableHead, TableRow, TableCell } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";

// ==================================|| STAFF WORKLOAD TABLE HEADER ||===================================== //

export const StaffWorkLoadTableHeader = ({ filteredStaffWorkLoadData }) => {
  const {
    labelText: {
      departmentText,
      totalHoursText,
      hoursText,
      sNoText,
      staffReferenceText,
    },
  } = uiTexts;

  const { departmentCount, courseTypeHours, staffCount, totalHours } =
    filteredStaffWorkLoadData?.staffWorkLoadOverAllDTO || {};

  const courseTypeHeaders =
    courseTypeHours?.map(
      (course) =>
        `${course.courseTypeName} ${hoursText} (${course.teachingHours})`,
    ) || [];

  const staffWorkLoadHeader = [
    sNoText,
    departmentText,
    staffReferenceText,
    totalHoursText,
    ...courseTypeHeaders,
  ];

  const getCount = (text) => {
    switch (text) {
      case departmentText:
        return `(${departmentCount})`;
      case staffReferenceText:
        return `(${staffCount})`;
      case totalHoursText:
        return `(${totalHours})`;
      default:
        return "";
    }
  };

  return (
    <TableHead>
      <TableRow>
        {staffWorkLoadHeader?.map((text, index) => (
          <TableCell
            className="infrastructure-utilization-table-cell"
            key={index}
          >
            {text}
            {getCount(text)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
