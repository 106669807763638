import React from "react";
import { Grid } from "@mui/material";
import CampusPlannerLogo from "../../Assets/Images/CampusPlannerLogo.png";

// =================================================|| RENDER LOGO ||============================================== //

export const RenderLogo = ({ imageStyle }) => {
  return (
    <Grid>
      <img src={CampusPlannerLogo} className={imageStyle} alt="logo" />
    </Grid>
  );
};
