import { uiTexts } from "../../../../../../Utils/uiTexts";
import "jspdf-autotable";
import CampusPlannerLogo from "../../../../../../Assets/Images/CampusPlannerLogo.png";
import VVVLogo from "../../../../../../Assets/Images/vvvclogo.png";

// =================================== || OVERALL PERCENTAGE REPORT || ======================================= //

export const overallPercentageReport = (doc, overAllPercentageData) => {
  const {
    labelText: { metricsText, achievementPercentageText, sNoText },
    pdfReportText: { vvvCollegeText, overAllPercentageReportText },
  } = uiTexts;

  const width = doc.internal.pageSize.getWidth();

  doc.setFontSize(18);
  doc.addImage(VVVLogo, "PNG", 10, 5, 15, 15);
  doc.addImage(CampusPlannerLogo, "JPG", 320, 5, 25, 15);
  doc.text(vvvCollegeText, width / 2, 15, { align: "center" });
  doc.setFontSize(13);
  doc.text(overAllPercentageReportText, width / 2, 25, {
    align: "center",
  });

  doc.setFontSize(12);
  const overAllPercentageHeaders = [
    sNoText,
    metricsText,
    achievementPercentageText,
  ];

  const overAllPercentageRows = Object.entries(overAllPercentageData)?.map(
    ([key, value], index) => [
      index + 1,
      key
        .replace(/([A-Z])/g, " $1") // Add space before uppercase letters
        .toLowerCase() // Convert entire string to lowercase
        .replace(/^./, (str) => str.toUpperCase()), // Capitalize first letter
      `${Math.round(value)}%`,
    ],
  );

  doc.autoTable({
    head: [overAllPercentageHeaders],
    body: overAllPercentageRows,
    startY: 40,
    theme: "grid",
    halign: "center",
    valign: "middle",
    styles: { fontSize: 10, minCellHeight: 10 },
  });
};
