import React from "react";
import { Box, Typography } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import CircularProgress from "@mui/material/CircularProgress";
import { InfrastructureUtilizationRhsInfo } from "./InfrastructureUtilizationRhsInfo";
import { InfrastructureUtilizationTable } from "./InfrastructureUtilizationTable";

// =======================================|| INFRASTRUCTURE UTILIZATION RHS CONTENT ||======================================= //

export const InfrastructureUtilizationRhsContent = ({
  fetchData,
  filteredInfrastructureData,
}) => {
  const {
    labelText: { noInfrastructureText },
  } = uiTexts;

  const renderContent = () => {
    switch (true) {
      case fetchData:
        return (
          <Box className="data-analysis-rhs-loader">
            <CircularProgress />
          </Box>
        );

      case filteredInfrastructureData?.details?.length > 0:
        return (
          <>
            <InfrastructureUtilizationRhsInfo
              filteredInfrastructureData={filteredInfrastructureData}
            />

            <InfrastructureUtilizationTable
              filteredInfrastructureData={filteredInfrastructureData}
            />
          </>
        );

      default:
        return (
          <Typography className="data-analysis-rhs-data-not-found">
            {noInfrastructureText}
          </Typography>
        );
    }
  };

  return renderContent();
};
