import React from "react";
import {
  Grid,
  Stack,
  Tooltip,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { AddClassModel } from "../../../Store/Reducers/class_menu";
import ClassModel from "./Classes Model/ClassModel";
import ClassList from "./ClassList";
import ClassContent from "./ClassContent";
import Loader from "../../Loader/Loader";
import AlgorithmWarnings from "../Timetable/Warnings/AlgorithmWarnings";
import "./ClassList.css";
import { SemesterSelectWarning } from "../../SemesterSelectWarning/SemesterSelectWarning";

// ==============================|| CLASS MAIN PAGE ||============================== //

const Classes = ({
  handleClassClick,
  handleRemoveClass,
  handleClassCourseClick,
  handleRemoveClassCourse,
}) => {
  const dispatch = useDispatch();

  const menu = useSelector((state) => state.class_menu);
  const addClassModel = menu.addClassModel;
  const classId = menu.classId;
  const classInfo = menu.classInfo;
  const isClassStatusCode = menu.isClassStatusCode;

  const infrastructure_menu = useSelector((state) => state.infrastructure_menu);
  const isBuildingStatusCode = infrastructure_menu.isBuildingStatusCode;
  const isDepartmentStatusCode = infrastructure_menu.isDepartmentStatusCode;

  const course_menu = useSelector((state) => state.course_menu);
  const isCourseStatusCode = course_menu.isCourseStatusCode;

  const combined_class_menu = useSelector((state) => state.combined_class_menu);
  const isCombinedClassStatusCode =
    combined_class_menu.isCombinedClassStatusCode;

  const staff_menu = useSelector((state) => state.staff_menu);
  const isStaffStatusCode = staff_menu.isStaffStatusCode;
  const isStaffCourseStatusCode = staff_menu.isStaffCourseStatusCodeAll;

  const fixedslot_menu = useSelector((state) => state.fixedslot_menu);
  const isFixedSlotStatusCode = fixedslot_menu.isFixedSlotStatusCode;

  const common_menu = useSelector((state) => state.menu);
  const algorithmStatus = common_menu.algorithmStatus;

  return (
    <>
      {(isBuildingStatusCode === false ||
        isDepartmentStatusCode === false ||
        isCourseStatusCode === false ||
        isClassStatusCode === false ||
        isCombinedClassStatusCode === false ||
        isStaffStatusCode === false ||
        isStaffCourseStatusCode === false ||
        isFixedSlotStatusCode === false ||
        algorithmStatus === "") &&
      sessionStorage.getItem("branchName") !== null ? (
        <Loader />
      ) : (
        <>
          {sessionStorage.getItem("branchName") !== null ? (
            <>
              <div className="display-warnings">
                <AlgorithmWarnings />
              </div>

              <>
                <Paper className="paper-padding" sx={{ mt: 0 }}>
                  <Stack direction="row">
                    <Tooltip title="Add Class" placement="right" arrow>
                      <IconButton
                        className={
                          algorithmStatus === null ||
                          algorithmStatus === "FAILED" ||
                          algorithmStatus === "UNKNOWN" ||
                          algorithmStatus === "NOT_CREATED"
                            ? "add-class-button"
                            : "add-class-button-disabled"
                        }
                        type="submit"
                        variant="contained"
                        onClick={() => {
                          dispatch(AddClassModel(true));
                        }}
                        disabled={
                          algorithmStatus !== null &&
                          algorithmStatus !== "FAILED" &&
                          algorithmStatus !== "UNKNOWN" &&
                          algorithmStatus !== "NOT_CREATED"
                        }
                      >
                        <AddIcon className="add-icon" />
                      </IconButton>
                    </Tooltip>

                    <Typography className="class-text">
                      <b>Classes</b>
                    </Typography>
                  </Stack>

                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <div className="classlist">
                        <ClassList
                          handleClassClick={handleClassClick}
                          handleRemoveClass={handleRemoveClass}
                        />

                        {addClassModel === true && <ClassModel />}
                      </div>
                    </Grid>

                    <Grid item xs={8}>
                      {classId && classInfo && (
                        <ClassContent
                          handleClassCourseClick={handleClassCourseClick}
                          handleRemoveClassCourse={handleRemoveClassCourse}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </>
            </>
          ) : (
            <SemesterSelectWarning />
          )}
        </>
      )}
    </>
  );
};

export default Classes;
