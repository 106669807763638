import { uiTexts } from "../../../../../../Utils/uiTexts";
import jsPDF from "jspdf";
import { overallPercentageReport } from "./OverallPercentageReport";
import { staffPreferenceMatchReport } from "./StaffPreferenceMatchReport";
import { staffWorkLoadBalancingReport } from "./StaffWorkLoadBalancingReport";
import { subjectBalancingReport } from "./SubjectBalancingReport";
import { subjectContinuoisHoursPreferenceMatchReport } from "./SubjectContinuousHoursPreferenceMatchReport";
import { groupSchedulesMatchReport } from "./GroupSchedulesMatchReport";

// ========================================== || RESULT ANALYSIS REPORT || ============================================= //

export const resultAnalysisReport = (
  overAllPercentageData,
  staffPreferenceMatchData,
  staffWorkLoadBalancingData,
  subjectBalancingData,
  subjectContinuousHoursPreferenceMatchData,
  groupSchedulesData,
  dayList,
  timeList,
) => {
  const {
    pdfReportNameText: { resultAnalysisReportText },
  } = uiTexts;

  const doc = new jsPDF({ orientation: "landscape", format: "legal" });

  overallPercentageReport(doc, overAllPercentageData);

  staffPreferenceMatchReport(doc, staffPreferenceMatchData);

  staffWorkLoadBalancingReport(doc, staffWorkLoadBalancingData);

  subjectBalancingReport(doc, subjectBalancingData);

  subjectContinuoisHoursPreferenceMatchReport(
    doc,
    subjectContinuousHoursPreferenceMatchData,
  );

  groupSchedulesMatchReport(doc, groupSchedulesData, dayList, timeList);

  doc.save(resultAnalysisReportText);
};
