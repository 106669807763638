// This file will be used for storing all the text and labels.

export const uiTexts = Object.freeze({
  labelText: {
    staffText: "Staff",
    departmentText: "Department",
    staffIdText: "staffId",
    dayTimeText: "DAY/TIME",
    courseText: "Course",
    classText: "Class",
    roomText: "Room",
    roomIdText: "roomId",
    buildingText: "Building",
    combinedClassText: "Combined Class",
    classIdText: "classId",
    classNameText: "className",
    roomNameText: "roomName",
    idText: "id",
    staffNameText: "staffName",
    courseCodeText: "Course code",
    courseNameText: "Course name",
    combinedClassNameText: "Combined class name",
    classNameLabelText: "Class name",
    numberOfStudentsText: "Number of students",
    roomNameLabelText: "Room name",
    staffNameLabelText: "Staff name",
    timeTableResultText: "Timetable result",
    fixedSlotResultText: "Fixed slot result",
    addGroupScheduleText: "Add Group Schedule",
    groupScheduleText: "Group Schedule",
    groupScheduleNameText: "Group schedule name",
    selectClassText: "Select Class",
    chooseText: "Choose",
    classCourseText: "Class Course",
    combinedClassCourseText: "Combined Class Course",
    selectClassCourseText: "Select Class Course",
    selectCombinedCourseText: "Select Combined Course",
    clearConfirmationText: "Clear Confirmation",
    areYouSureYouWantToDeleteText: "Are you sure you want to Delete ?",
    editGroupScheduleText: "Edit Group Schedule",
    groupScheduleNameCamelCaseText: "groupScheduleName",
    possibleRoomsCapacityText: "Possible Rooms - Capacity",
    chooseDayPreferenceText: "Choose Day Preference",
    deleteAllCoursesText: "Delete All Courses",
    classNameOrCombinedClassNameText: "Class Name / Combined Class Name",
    chooseBuildingText: "Choose building",
    chooseRoomTypeText: "Choose room type",
    allText: "All",
    searchRoomText: "Search room",
    roomsWhereAllSubjectsCanBeAssigned:
      "Rooms where all subjects can be assigned",
    roomsWhereAllSubjectsCannotBeAssigned:
      "Rooms where all subjects cannot be assigned",
    roomTypeText: "Room type",
    buildingNameText: "buildingName",
    buildingIdText: "buildingId",
    roomCountText: "roomCount",
    roomTypeNameText: "roomTypeName",
    roomTypeCountText: "roomTypeCount",
    noInfrastructureText: "No infrastructure",
    chooseDepartmentText: "Choose department",
    departmentIdText: "departmentId",
    departmentNameText: "departmentName",
    staffCountText: "staffCount",
    searchStaffText: "Search staff",
    noStaffText: "No staff",
    staffPreferencesMatchedText: "Staff preferences matched",
    staffPreferencesNotMatchedText: "Staff preferences not matched",
    overAllMatchPercentageText: "Overall match percentage",
    sNoText: "S.No",
    staffReferenceText: "Staff (Reference)",
    subjectText: "Subject",
    preferredDayText: "Preferred day",
    isPreferenceMatchingText: "Is preference matching",
    staffAssignedDuringTheFirstHourAcrossAllDaysText:
      "Staff assigned during the first hour across all days",
    staffAssignedDuringTheLastHourAcrossAllDaysText:
      "Staff assigned during the last hour across all days",
    noOfStaffWithNoAssignedHoursOnAnyDayText:
      "Number of staff with no assigned hours on any day",
    teachingHoursWithoutABreakExcludingContinuousLabHoursText:
      "Teaching hours without a break (Excluding continuous lab hours)",
    workLoadBalanceMatchPercentageText: "Workload balance match percentage",
    excludingContinuousLabHoursText: "(Excluding continuous lab hours)",
    firstHourText: "first hour",
    lastHourText: "last hour",
    chooseClassText: "Choose class",
    searchSubjectText: "Search subject",
    noSubjectText: "No subject",
    typeCombinedGroupIndividualText: "Type (Combined/Group/Individual)",
    subjectHoursText: "Subject hours",
    daysAllottedText: "Days allotted",
    subjectBalancePercentageText: "Subject balance percentage",
    combinedGroupIndividualText: "(Combined/Group/Individual)",
    classCountText: "classCount",
    noClassText: "No class",
    continuousHourPreferencesText: "Continuous hour preferences",
    nonContinuousHoursPreferencesText: "Non-Continuous hours preferences",
    hourDistributionText: "Hour distribution",
    isContinuousText: "Is continuous",
    metricsText: "Metrics",
    achievementPercentageText: "Achievement %",
    searchClassText: "Search class",
    searchGroupScheduleText: "Search group schedule",
    noGroupScheduleText: "No group schedule",
    matchedGroupSchedulesText: "Matched group schedules",
    unmatchedGroupSchedulesText: "Unmatched group schedules",
    dayAndTimeSlotsText: "Day and time slots",
    subjectsText: "Subjects",
    totalHoursWithOutBreaksText: "Total hours without breaks",
    staffExceedingIdealTeachingHours: "Staff exceeding ideal teaching hours",
    staffBelowIdealTeachingHours: "Staff below ideal teaching hours",
    hoursText: "hours",
    totalHoursText: "Total hours",
    idealTeachingHoursText: "Ideal teaching hours",
    roomCapacityText: "Room (Capacity)",
    isMultipleSubjectsAllowedText: "Is multiple subjects allowed",
    availableSlotsText: "Available slots",
    possibleSubjectsText: "Possible subjects",
    totalSubjectHoursText: "Total subject hours",
    canAssignAllSubjectsText: "Can assign all subjects",
    theoryText: "Theory",
    labText: "Lab",
    subjectLowerCaseText: "subject",
    subjectNameText: "subjectName",
  },

  buttonText: {
    timelineText: "Timeline",
    tableText: "Table",
    editText: "Edit",
    deleteText: "Delete",
    saveText: "Save",
    cancelText: "Cancel",
    yesText: "Yes",
    noText: "No",
    viewText: "View",
    downloadText: "Download",
    deselectAllText: "Deselect All",
    selectAllText: "Select All",
  },

  symbolText: {
    colonText: ": ",
  },

  placeHolderText: {
    enterGroupScheduleNameText: "Enter group schedule name",
  },

  modalHeaderText: {
    addCourseForGroupScheduleText: "Add Course for Group Schedule",
  },

  algorithmStatusText: {
    failedText: "FAILED",
    unknownText: "UNKNOWN",
    notCreatedText: "NOT_CREATED",
  },

  loginText: {
    usernameAndPasswordIsRequiredText: "Username and password is required",
    usernameIsRequiredText: "Username is required",
    passwordIsRequiredText: "Password is required",
    noMoreDataText: "No more data",
    invalidCredentialsText: "Invalid credentials",
    somethingWentWrongText: "Something went wrong",
    userNameText: "Username",
    passwordText: "Password",
    loginText: "Login",
    usernameLowerCaseText: "username",
    enterUserNameText: "Enter username",
    passwordLowerCaseText: "password",
    enterPasswordText: "Enter password",
    text: "text",
  },

  headerText: {
    semesterTypeText: "semesterType",
    academicYearText: "academicYear",
    homeText: "Home",
    logoutText: "Logout",
  },

  alertTitleText: {
    dataAnalysisText: "Data analysis",
    resultAnalysisText: "Result analysis",
  },

  analysisModalListText: {
    infrastructureUtilizationText: "Infrastructure utilization",
    staffWorkloadText: "Staff workload",
    overAllPercentageText: "Overall percentage",
    staffPreferenceMatchText: "Staff preference match",
    staffWorkLoadBalancingText: "Staff workload balancing",
    subjectBalancingText: "Subject balancing",
    subjectContinuousHoursPreferenceMatchText:
      "Subject continuous hours preference match",
    groupSchedulesText: "Group schedules",
  },

  pdfReportNameText: {
    dataAnalysisReportText: "DataAnalysisReport.pdf",
    resultAnalysisReportText: "ResultAnalysisReport.pdf",
  },

  pdfReportText: {
    vvvCollegeText: "V.V.Vanniaperumal College for Women (Autonomous)",
    staffWorkloadReportText: "Staff Workload Report",
    infrastructureUtilizationReportText: "Infrastructure Utilization Report",
    overAllPercentageReportText: "Overall Percentage Report",
    staffPreferenceMatchReportText: "Staff Preference Match Report",
    staffWorkLoadBalancingReportText: "Staff Workload Balancing Report",
    subjectBalancingReportText: "Subject Balancing Report",
    subjectContinuousHoursPreferenceMatchReportText:
      "Subject Continuous Hours Preference Match Report",
    groupSchedulesReportText: "Group Schedules Report",
  },

  toolTipText: {
    groupSchedulingMismatchText: "Group scheduling mismatch",
  },

  homePageText: {
    newSemesterText: "New Semester",
    addNewSemesterText: "Add New Semester",
    semesterDataToBeImportedText: "Semester data to be imported",
    chooseSemesterText: "Choose semester",
    idText: "id",
    tenantNameText: "tenantName",
    academicYearText: "Academic year",
    chooseAcademicyearText: "Choose academic year",
    academicYearCamelCaseText: "academicYear",
    semesterTypeText: "Semester type",
  },

  semesterSelectWarningText: {
    pleaseSelectASemesterText: "Please Select a Semester",
  },
});
