import React from "react";
import { Stack } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { AnalysisRhsInfo } from "../../../../Analysis/AnalysisCommonComponents/AnalysisRhsInfo";

// =======================================|| INFRASTRUCTURE UTILIZATION RHS INFO ||======================================= //

export const InfrastructureUtilizationRhsInfo = ({
  filteredInfrastructureData,
}) => {
  const {
    labelText: {
      roomsWhereAllSubjectsCanBeAssigned,
      roomsWhereAllSubjectsCannotBeAssigned,
    },
  } = uiTexts;

  const { allSubjectsCanBeAssigned, allSubjectsCannotBeAssigned } =
    filteredInfrastructureData || {};

  return (
    <Stack
      direction="row"
      className="data-analysis-filter-part data-analysis-info-gap"
    >
      <AnalysisRhsInfo
        label={roomsWhereAllSubjectsCanBeAssigned}
        value={allSubjectsCanBeAssigned}
      />

      <AnalysisRhsInfo
        label={roomsWhereAllSubjectsCannotBeAssigned}
        value={allSubjectsCannotBeAssigned}
      />
    </Stack>
  );
};
