import React from "react";
import { Grid, Box } from "@mui/material";
import { InfrastructureUtilization } from "../InfrastructureUtilization/InfrastructureUtilization";
import { StaffWorkload } from "../StaffWorkload/StaffWorkload";
import "../../../../../../Dashboard/ShortcutModal/shortcutmodal.css";
import "../../dataanalysis.css";

// ====================================== DATA ANALYSIS MODAL RHS ===================================== //

export const DataAnalysisModalRhs = ({ showSelectedOption }) => {
  return (
    <Grid className="data-analysis-content">
      <Box width="100%">
        {showSelectedOption === 1 && <InfrastructureUtilization />}

        {showSelectedOption === 2 && <StaffWorkload />}
      </Box>
    </Grid>
  );
};
