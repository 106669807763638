import React from "react";
import { Grid, Table, TableContainer } from "@mui/material";
import { SubjectBalancingTableHeader } from "./SubjectBalancingTableHeader";
import { SubjectBalancingTableItem } from "./SubjectBalancingTableItem";

// =======================================|| SUBJECT BALANCING TABLE ||======================================== //

export const SubjectBalancingTable = ({ filteredSubjectBalancingData }) => {
  return (
    <Grid item xs={12}>
      <TableContainer className="result-analysis-subject-balancing-scroll">
        <Table stickyHeader>
          <SubjectBalancingTableHeader
            filteredSubjectBalancingData={filteredSubjectBalancingData}
          />

          <SubjectBalancingTableItem
            filteredSubjectBalancingData={filteredSubjectBalancingData}
          />
        </Table>
      </TableContainer>
    </Grid>
  );
};
