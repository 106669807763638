import React, { useState, useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { getStaffPreferenceMatch } from "../../../../../../../Services/ResultAnalysisService";
import { StaffPreferenceMatchFilters } from "./StaffPreferenceMatchFilters";
import { StaffPreferenceRhsContent } from "./StaffPreferenceMatchRhsContent";

// =======================================|| STAFF PREFERENCE MATCH ||======================================= //

export const StaffPreferenceMatch = () => {
  const departmentList = useSelector(
    (state) => state.infrastructure_menu.departmentList,
  );

  const [fetchData, setFetchData] = useState(false);
  const [selectedDepartments, setSelectedDepartments] =
    useState(departmentList);
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState();
  const [searchData, setSearchData] = useState("");
  const [
    filteredStaffPreferenceMatchData,
    setFilteredStaffPreferenceMatchData,
  ] = useState();

  useEffect(() => {
    setSelectedDepartmentIds(
      selectedDepartments?.map((department) => department.id),
    );
  }, [selectedDepartments]);

  const getFilteredData = async () => {
    setFetchData(true);

    const staffPreferenceMatchData = {
      departmentIds: selectedDepartmentIds,
    };

    await getStaffPreferenceMatch(staffPreferenceMatchData).then(({ data }) => {
      const filteredDetails = data?.staffPreferenceMatchDTOList?.filter(
        (staff) =>
          staff.staffName.toLowerCase().includes(searchData?.toLowerCase()),
      );

      const updatedStaffPreferenceMatchData = {
        ...data,
        staffPreferenceMatchDTOList: filteredDetails,
      };

      setFilteredStaffPreferenceMatchData(updatedStaffPreferenceMatchData);
      setFetchData(false);
    });
  };

  useEffect(() => {
    getFilteredData();
  }, [selectedDepartmentIds, searchData]);

  return (
    <Box className="data-analysis-modal-rhs-content">
      <Stack className="data-analysis-filter-part">
        <StaffPreferenceMatchFilters
          departments={departmentList}
          selectedDepartments={selectedDepartments}
          setSelectedDepartments={setSelectedDepartments}
          setSelectedDepartmentIds={setSelectedDepartmentIds}
          searchData={searchData}
          setSearchData={setSearchData}
        />

        <StaffPreferenceRhsContent
          fetchData={fetchData}
          filteredStaffPreferenceMatchData={filteredStaffPreferenceMatchData}
        />
      </Stack>
    </Box>
  );
};
