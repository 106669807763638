import React from "react";
import { Grid, Typography, IconButton, Tooltip } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeUserSession, setTabValue } from "../../Utils/commonMethods";
import { SemesterLoader } from "../../Store/Reducers/login_menu";
import { uiTexts } from "../../Utils/uiTexts";
import { RenderLogo } from "../RenderLogo/RenderLogo";
import "./headerstyle.css";

// =================================================|| HEADER ||============================================== //

export const Header = () => {
  const {
    headerText: { semesterTypeText, academicYearText, homeText, logoutText },
  } = uiTexts;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    removeUserSession();
    navigate("/");
  };

  const navigateToHome = () => {
    navigate("/home");
    setTabValue(null);
    dispatch(SemesterLoader(false));
  };

  const RenderToolTipIcon = ({ title, handleClick, Icon, iconStyle }) => {
    return (
      <Tooltip title={title} placement="bottom" arrow>
        <IconButton
          className="logout-iconButton"
          type="submit"
          variant="contained"
        >
          <Icon onClick={() => handleClick()} className={iconStyle} />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <Grid className="header">
      <Grid className="header-layout" component="main">
        <RenderLogo imageStyle="campusplanner-logo" />

        <Grid>
          <Typography className="semesterName-text">
            <span>{sessionStorage.getItem(semesterTypeText)}</span>
            <span>{sessionStorage.getItem(academicYearText)}</span>
          </Typography>
        </Grid>
      </Grid>

      <RenderToolTipIcon
        title={homeText}
        handleClick={navigateToHome}
        Icon={HomeIcon}
        iconStyle="home-icon"
      />

      <RenderToolTipIcon
        title={logoutText}
        handleClick={handleLogout}
        Icon={LogoutIcon}
        iconStyle="logout-icon"
      />
    </Grid>
  );
};
