import React from "react";
import { Typography } from "@mui/material";
import { uiTexts } from "../../../../../Utils/uiTexts";

// =======================================|| ANALYSIS RHS INFO ||======================================= //

export const AnalysisRhsInfo = ({ label, value }) => {
  const {
    symbolText: { colonText },
    labelText: { firstHourText, lastHourText },
  } = uiTexts;

  // Function to render bold text for specific words
  const renderBoldText = (text) => {
    const boldWords = [firstHourText, lastHourText];
    const regex = new RegExp(`(${boldWords?.join("|")})`, "gi");

    return text
      .split(regex)
      .map((part, index) =>
        boldWords?.includes(part) ? <strong key={index}>{part}</strong> : part,
      );
  };

  return (
    <Typography className="data-analysis-rhs-info-text">
      {renderBoldText(label)} {colonText}
      <span className="data-analysis-rhs-info-value">{value}</span>
    </Typography>
  );
};
