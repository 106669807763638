import React from "react";
import { Box, Typography } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import CircularProgress from "@mui/material/CircularProgress";
import { GroupSchedulesRhsInfo } from "./GroupSchedulesRhsInfo";
import { GroupSchedulesAccordion } from "./GroupSchedulesAccordion";

// ====================================|| GROUP SCHEDULES RHS CONTENT ||===================================== //

export const GroupSchedulesRhsContent = ({
  fetchData,
  filteredGroupSchedulesData,
}) => {
  const {
    labelText: { noGroupScheduleText },
  } = uiTexts;

  const showRhsContent =
    filteredGroupSchedulesData?.groupScheduleResultDTOList?.length > 0;

  const renderContent = () => {
    switch (true) {
      case fetchData:
        return (
          <Box className="data-analysis-rhs-loader">
            <CircularProgress />
          </Box>
        );

      case showRhsContent:
        return (
          <>
            <GroupSchedulesRhsInfo
              filteredGroupSchedulesData={filteredGroupSchedulesData}
            />

            <GroupSchedulesAccordion
              filteredGroupSchedulesData={filteredGroupSchedulesData}
            />
          </>
        );

      default:
        return (
          <Typography className="data-analysis-rhs-data-not-found">
            {noGroupScheduleText}
          </Typography>
        );
    }
  };

  return <>{renderContent()}</>;
};
