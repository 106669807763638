import React from "react";
import { Grid, Table, TableContainer } from "@mui/material";
import { InfrastructureUtilizationTableHeader } from "./InfrastructureUtilizationTableHeader";
import { InfrastructureUtilizationTableItem } from "./InfrastructureUtilizationTableItem";

// =======================================|| INFRASTRUCTURE UTILIZATION TABLE ||======================================== //

export const InfrastructureUtilizationTable = ({
  filteredInfrastructureData,
}) => {
  return (
    <Grid item xs={12}>
      <TableContainer className="data-analysis-modal-rhs-content-scroll">
        <Table stickyHeader>
          <InfrastructureUtilizationTableHeader
            filteredInfrastructureData={filteredInfrastructureData}
          />

          <InfrastructureUtilizationTableItem
            filteredInfrastructureData={filteredInfrastructureData}
          />
        </Table>
      </TableContainer>
    </Grid>
  );
};
