import React from "react";
import { TableBody, TableRow, TableCell } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { sortDataByLocaleCompare } from "../../../../../../../Utils/commonMethods";
import { AnalysisRhsTableItem } from "../../../AnalysisCommonComponents/AnalysisRhsTableItem";
import "../../../DataAnalysis/dataanalysis.css";

// ========================|| SUBJECT CONTINUOUS HOURS PREFERENCE MATCH TABLE ITEM ||============================= //

export const SubjectContinuousHoursPreferenceMatchTableItem = ({
  filteredSubjectContinuousHoursPreferenceMatchData,
}) => {
  const {
    buttonText: { yesText, noText },
    labelText: { departmentNameText },
  } = uiTexts;

  const sortedFilteredData = sortDataByLocaleCompare(
    filteredSubjectContinuousHoursPreferenceMatchData,
    departmentNameText,
  );

  const RenderDayAndTime = ({ item, highlight }) => {
    return (
      <TableCell
        className="infrastructure-utilization-table-cell infrastructure-utilization-table-item"
        sx={{
          color: highlight ? "#FF0000" : "#000",
          whiteSpace: "nowrap",
        }}
      >
        {item.split(" | ")?.map((entry, index) => (
          <div key={index}>{entry.replace("Time", "- Time")}</div>
        ))}
      </TableCell>
    );
  };

  return (
    <TableBody>
      {sortedFilteredData?.map((detail, index) => (
        <TableRow
          key={index}
          className={
            !detail.efficiencyMatched &&
            "infrastructure-utilization-table-row-highlight"
          }
        >
          <AnalysisRhsTableItem
            item={index + 1}
            highlight={!detail.efficiencyMatched}
          />

          <AnalysisRhsTableItem
            item={detail.departmentName}
            highlight={!detail.efficiencyMatched}
          />

          <AnalysisRhsTableItem
            item={detail.className}
            highlight={!detail.efficiencyMatched}
          />

          <AnalysisRhsTableItem
            item={detail.subjectName}
            highlight={!detail.efficiencyMatched}
          />

          <AnalysisRhsTableItem
            item={detail.subjectHours}
            highlight={!detail.efficiencyMatched}
          />

          <RenderDayAndTime
            item={detail.resultDayAndTime}
            highlight={!detail.efficiencyMatched}
          />

          <AnalysisRhsTableItem
            item={detail.efficiencyMatched ? yesText : noText}
            highlight={!detail.efficiencyMatched}
          />
        </TableRow>
      ))}
    </TableBody>
  );
};
