import React, { useEffect } from "react";
import { Grid, Box, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getUserName, removeUserSession } from "../../Utils/commonMethods";
import { LoginActions } from "./LoginActions";
import { RenderLogo } from "../RenderLogo/RenderLogo";
import "./loginstyle.css";

// ===========================================|| LOGIN ||========================================= //

const Login = () => {
  const navigate = useNavigate();
  const userName = getUserName();

  useEffect(() => {
    removeUserSession();
    if (userName === null) navigate("/");
  }, []);

  return (
    <Grid container component="main" className="background-Login">
      <div className="login">
        <Container component="main" maxWidth="sm">
          <Box
            className="login-box"
            sx={{
              boxShadow: 1,
              px: 2,
              py: 6,
            }}
            noValidate
            autoComplete="off"
          >
            <RenderLogo imageStyle="campusplanner-logo-login" />

            <LoginActions />
          </Box>
        </Container>
      </div>
    </Grid>
  );
};

export default Login;
