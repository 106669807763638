import { uiTexts } from "./uiTexts";
const {
  labelText: {
    metricsText,
    achievementPercentageText,
    sNoText,
    departmentText,
    subjectText,
    preferredDayText,
    isPreferenceMatchingText,
    teachingHoursWithoutABreakExcludingContinuousLabHoursText,
    workLoadBalanceMatchPercentageText,
    classText,
    typeCombinedGroupIndividualText,
    subjectHoursText,
    daysAllottedText,
    subjectBalancePercentageText,
    hourDistributionText,
    isContinuousText,
    subjectsText,
    dayAndTimeSlotsText,
    staffText,
  },
  analysisModalListText: {
    overAllPercentageText,
    staffPreferenceMatchText,
    staffWorkLoadBalancingText,
    subjectBalancingText,
    subjectContinuousHoursPreferenceMatchText,
    groupSchedulesText,
  },
} = uiTexts;

// ========================================= GLOBAL UTILS ============================================== //

// Result Analysis Modal Related Header
export const resultAnalysisTabOptions = [
  { id: 1, tabName: overAllPercentageText },
  { id: 2, tabName: staffPreferenceMatchText },
  { id: 3, tabName: staffWorkLoadBalancingText },
  { id: 4, tabName: subjectBalancingText },
  { id: 5, tabName: subjectContinuousHoursPreferenceMatchText },
  { id: 6, tabName: groupSchedulesText },
];

export const overAllPercentageHeader = [metricsText, achievementPercentageText];

export const staffPreferenceMatchHeader = [
  sNoText,
  departmentText,
  staffText,
  subjectText,
  preferredDayText,
  isPreferenceMatchingText,
];

export const staffWorkLoadBalancingHeader = [
  sNoText,
  departmentText,
  staffText,
  teachingHoursWithoutABreakExcludingContinuousLabHoursText,
  workLoadBalanceMatchPercentageText,
];

export const subjectBalancingHeader = [
  sNoText,
  departmentText,
  classText,
  subjectText,
  typeCombinedGroupIndividualText,
  subjectHoursText,
  daysAllottedText,
  subjectBalancePercentageText,
];

export const subjectContinuousHoursPreferenceMatchHeader = [
  sNoText,
  departmentText,
  classText,
  subjectText,
  subjectHoursText,
  hourDistributionText,
  isContinuousText,
];

export const groupSchedulesAccordionHeader = [
  subjectsText,
  dayAndTimeSlotsText,
];

export const homePageTableHeader = [
  "",
  "S.No",
  "Academic year",
  "Semester type",
  "Created date",
  "Updated date",
  "Completed date",
  "Status",
];
