import React from "react";
import { Stack } from "@mui/material";
import { uiTexts } from "../../../../../../../Utils/uiTexts";
import { AnalysisModalFilterDropdown } from "../../../../Analysis/AnalysisCommonComponents/AnalysisModalFilterDropdown";
import { AnalysisModalSearchTextField } from "../../../../Analysis/AnalysisCommonComponents/AnalysisModalSearchTextField";

// =======================================|| INFRASTRUCTURE UTILIZATION FILTERS ||======================================= //

export const InfrastructureUtilizationFilters = ({
  buildings,
  selectedBuildings,
  setSelectedBuildings,
  roomTypes,
  selectedRoomTypes,
  setSelectedRoomTypes,
  searchData,
  setSearchData,
  setSelectedBuildingIds,
  setSelectedRoomTypesName,
}) => {
  const {
    labelText: {
      chooseBuildingText,
      chooseRoomTypeText,
      buildingIdText,
      buildingNameText,
      roomCountText,
      roomTypeNameText,
      roomTypeCountText,
      searchRoomText,
    },
  } = uiTexts;

  const handleBuildingChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedBuildings(value);
    setSelectedBuildingIds(value?.map((building) => building.buildingId));
  };

  const handleRoomTypeChange = (event) => {
    const { value } = event.target;

    // Count occurrences of each `roomTypeName`
    const countMap = value?.reduce((acc, room) => {
      acc[room.roomTypeName] = (acc[room.roomTypeName] || 0) + 1;
      return acc;
    }, {});

    // Filter out room types that appear exactly twice
    const filteredRoomTypes = value?.filter(
      (room) => countMap[room.roomTypeName] !== 2,
    );

    // Remove duplicates based on `roomTypeName`
    const uniqueRoomTypes = Array.from(
      new Map(
        filteredRoomTypes?.map((room) => [room.roomTypeName, room]),
      ).values(),
    );

    setSelectedRoomTypes(uniqueRoomTypes);
    setSelectedRoomTypesName(uniqueRoomTypes?.map((room) => room.roomTypeName));
  };

  const handleClear = (setter1, setter2, value, keyLabel) => {
    setter1(value);
    setter2(value?.map((data) => data[keyLabel]));
  };

  const handleDeselectAll = (setter1, setter2) => {
    setter1([]);
    setter2([]);
  };

  const handleSearch = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <Stack direction="row" className="data-analysis-filter-part">
      <AnalysisModalFilterDropdown
        dropdownWidth={300}
        dropdownData={buildings}
        selectedOption={selectedBuildings}
        label={chooseBuildingText}
        handleChange={handleBuildingChange}
        handleClear={(e) => {
          e.stopPropagation();
          handleClear(
            setSelectedBuildings,
            setSelectedBuildingIds,
            buildings,
            buildingIdText,
          );
        }}
        handleDeselectAll={() =>
          handleDeselectAll(setSelectedBuildings, setSelectedBuildingIds)
        }
        keyLabel={buildingNameText}
        keyId={buildingIdText}
        count={roomCountText}
      />

      <AnalysisModalFilterDropdown
        dropdownWidth={250}
        dropdownData={roomTypes}
        selectedOption={selectedRoomTypes}
        label={chooseRoomTypeText}
        handleChange={handleRoomTypeChange}
        handleClear={(e) => {
          e.stopPropagation();
          handleClear(
            setSelectedRoomTypes,
            setSelectedRoomTypesName,
            roomTypes,
            roomTypeNameText,
          );
        }}
        handleDeselectAll={() =>
          handleDeselectAll(setSelectedRoomTypes, setSelectedRoomTypesName)
        }
        keyLabel={roomTypeNameText}
        keyId={roomTypeNameText}
        count={roomTypeCountText}
      />

      <AnalysisModalSearchTextField
        searchData={searchData}
        handleSearch={handleSearch}
        label={searchRoomText}
        setSearchData={setSearchData}
      />
    </Stack>
  );
};
